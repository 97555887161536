import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPin.module.css';

const IconPin = props => {
  const { className, rootClassName, isActive } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.activeRoot]: isActive,
  });

  if (isActive) {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="51"
        fill="none"
        viewBox="0 0 31 51"
      >
        <mask id="path-1-inside-1_304_46" fill="#fff">
          <path
            fillRule="evenodd"
            d="M28.471 22.497a15.107 15.107 0 001.88-7.321C30.351 6.794 23.557 0 15.176 0 6.794 0 0 6.794 0 15.176c0 2.6.654 5.049 1.807 7.188L15.05 51l13.421-28.503z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28.471 22.497a15.107 15.107 0 001.88-7.321C30.351 6.794 23.557 0 15.176 0 6.794 0 0 6.794 0 15.176c0 2.6.654 5.049 1.807 7.188L15.05 51l13.421-28.503z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#B60101"
          d="M28.471 22.497l-.875-.483-.016.028-.013.03.904.425zm-26.664-.133l.908-.42-.013-.027-.014-.027-.88.474zM15.05 51l-.907.42.9 1.945.912-1.939L15.05 51zm14.301-35.824c0 2.481-.637 4.811-1.755 6.838l1.75.966a16.108 16.108 0 002.005-7.804h-2zM15.176 1C23.005 1 29.35 7.347 29.35 15.176h2C31.351 6.242 24.11-1 15.176-1v2zM1 15.176C1 7.346 7.347 1 15.176 1v-2C6.242-1-1 6.242-1 15.176h2zm1.688 6.714A14.108 14.108 0 011 15.176h-2c0 2.77.697 5.381 1.927 7.663l1.76-.949zm13.27 28.69L2.715 21.945l-1.815.84L14.143 51.42l1.815-.84zm11.609-28.509L14.146 50.574l1.81.852 13.42-28.503-1.81-.852z"
          mask="url(#path-1-inside-1_304_46)"
        ></path>
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="35"
      fill="none"
      viewBox="0 0 27 35"
    >
      <mask id="path-1-inside-1_1585_5008" fill="#fff">
        <path
          fillRule="evenodd"
          d="M22.303 22.569a13.104 13.104 0 003.983-9.426C26.287 5.884 20.402 0 13.143 0S0 5.884 0 13.143c0 1.787.357 3.491 1.003 5.045H.866l.49.778c.403.813.887 1.578 1.442 2.285l7.57 12.003c1.17 1.857 3.875 1.868 5.061.02l6.874-10.705z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#B60101"
        fillRule="evenodd"
        d="M22.303 22.569a13.104 13.104 0 003.983-9.426C26.287 5.884 20.402 0 13.143 0S0 5.884 0 13.143c0 1.787.357 3.491 1.003 5.045H.866l.49.778c.403.813.887 1.578 1.442 2.285l7.57 12.003c1.17 1.857 3.875 1.868 5.061.02l6.874-10.705z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M22.303 22.569l-.697-.717-.082.08-.062.097.841.54zm-21.3-4.381v1h1.499l-.576-1.384-.923.384zm-.137 0v-1H-.947L.02 18.72l.846-.533zm.49.778l.897-.444-.023-.046-.027-.044-.846.534zm1.442 2.285l.846-.534-.028-.043-.032-.04-.786.617zm7.57 12.003l.845-.534-.846.534zm5.061.02l-.841-.54.841.54zm9.857-20.13c0 3.415-1.409 6.5-3.68 8.708L23 23.286a14.104 14.104 0 004.287-10.143h-2zM13.143 1c6.707 0 12.143 5.437 12.143 12.143h2C27.287 5.333 20.954-1 13.143-1v2zM1 13.143C1 6.437 6.437 1 13.143 1v-2C5.333-1-1 5.332-1 13.143h2zm.926 4.66A12.104 12.104 0 011 13.144h-2c0 1.922.384 3.756 1.08 5.429l1.846-.768zm-1.06 1.385h.137v-2H.866v2zm1.337-.756l-.491-.778L.02 18.721l.491.778 1.692-1.067zm1.381 2.201c-.512-.653-.96-1.36-1.33-2.11l-1.793.886c.433.875.953 1.699 1.55 2.46l1.573-1.236zm7.63 12.087l-7.57-12.003-1.692 1.067 7.57 12.003 1.691-1.067zm3.374.014a2 2 0 01-3.375-.014l-1.692 1.067c1.562 2.477 5.168 2.491 6.75.028l-1.683-1.081zm6.874-10.705l-6.874 10.705 1.683 1.08 6.873-10.705-1.682-1.08z"
        mask="url(#path-1-inside-1_1585_5008)"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconPin.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPin.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPin;
