import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';

// Import configs and util modules
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';
import { propTypes } from '../../../util/types';

// Import modules from this directory
import CreateTalentBasicsPanel from './CreateTalentBasicsPanel/CreateTalentBasicsPanel';
import CreateTalentWorkPanel from './CreateTalentWorkPanel/CreateTalentWorkPanel';
import CreateTalentSpecificsPanel from './CreateTalentSpecificsPanel/CreateTalentSpecificsPanel';
import CreateTalentStylePanel from './CreateTalentStylePanel/CreateTalentStylePanel';
import CreateTalentPortfolioPanel from './CreateTalentPortfolioPanel/CreateTalentPortfolioPanel';

import css from './CreateTalentWizardTab.module.css';

export const BASICS = 'basics';
export const WORK = 'work';
export const SPECIFICS = 'specifics';
export const STYLE = 'style';
export const PORTFOLIO = 'portfolio';

// CreateTalentWizardTab component supports these tabs
export const SUPPORTED_TABS = [BASICS, WORK, SPECIFICS, STYLE, PORTFOLIO];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next CreateTalentWizardTab
const redirectAfterDraftUpdate = (params, tab, marketplaceTabs, history, routes) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
  };

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('CreateTalentPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString('CreateTalentPage', routes, nextPathParams, {});
  history.push(to);
};

const pathParamsToPreviousTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab =
    prevTabIndex < marketplaceTabs.length ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];

  return { ...params, tab: prevTab };
};

const redirectToPreviousTab = (params, tab, marketplaceTabs, routes, history) => {
  // Redirect to previous tab
  const previousPathParams = pathParamsToPreviousTab(params, tab, marketplaceTabs);

  const to = previousPathParams.tab
    ? createResourceLocatorString('CreateTalentPage', routes, previousPathParams, {})
    : createResourceLocatorString('LandingPage', routes, {}, {});
  history.push(to);
};

const CreateTalentWizardTab = props => {
  const {
    tab,
    tabLabel,
    marketplaceTabs,
    params,
    locationSearch,
    errors,
    currentProfile,
    newListingPublished,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    history,
    onUpdateProfile,
    onCreateProfileDraft,
    onEmailTaken,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    tabSubmitButtonText,
    isEmailTaken,
    config,
    routeConfiguration,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  // New listing flow has automatic redirects to new tab on the wizard
  // and the last panel calls publishListing API endpoint.
  const automaticRedirectsForNewListingFlow = (tab, updateValues) => {
    if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
      // Create listing flow: smooth scrolling polyfill to scroll to correct tab
      handleCreateFlowTabScrolling(false);

      // After successful saving of draft data, user should be redirected to next tab
      redirectAfterDraftUpdate(params, tab, marketplaceTabs, history, routeConfiguration);
    } else {
      handlePublishListing(updateValues);
    }
  };

  const onCompleteCreateTalentWizardTab = (tab, updateValues) => {
    const onUpdateProfileOrCreateListingDraft = isNewURI
      ? (tab, values) => onCreateProfileDraft(values, config)
      : (tab, values) => onUpdateProfile(tab, values, config);

    const updateListingValues = isNewURI ? updateValues : { ...updateValues };
    const updateListingValuesForPublishingListing = { ...updateListingValues, ...currentProfile };
    return onUpdateProfileOrCreateListingDraft(tab, updateListingValues)
      .then(r => {
        // In Availability tab, the submitted data (plan) is inside a modal
        // We don't redirect provider immediately after plan is set
        if (isNewListingFlow) {
          automaticRedirectsForNewListingFlow(tab, updateListingValuesForPublishingListing);
        }
      })
      .catch(e => {
        // No need for extra actions
      });
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      panelUpdated: updatedTab === tab,
      params,
      locationSearch,
      updateInProgress,
      marketplaceTabs,
      currentTab: tab,
      currentProfile,
      tabLabel,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: false,
      submitButtonText: tabSubmitButtonText,
      onManageDisableScrolling,
      onRedirectToPreviousTab: () =>
        redirectToPreviousTab(params, tab, marketplaceTabs, routeConfiguration, history),
      onSubmit: values => {
        return onCompleteCreateTalentWizardTab(tab, values);
      },
    };
  };

  // TODO: add missing cases for supported tabs
  switch (tab) {
    case BASICS: {
      return (
        <CreateTalentBasicsPanel
          {...panelProps(BASICS)}
          config={config}
          isEmailTaken={isEmailTaken}
          onEmailTaken={onEmailTaken}
        />
      );
    }
    case WORK: {
      return <CreateTalentWorkPanel {...panelProps(WORK)} config={config} />;
    }
    case SPECIFICS: {
      return <CreateTalentSpecificsPanel {...panelProps(SPECIFICS)} config={config} />;
    }
    case STYLE: {
      return <CreateTalentStylePanel {...panelProps(STYLE)} config={config} />;
    }
    case PORTFOLIO: {
      return <CreateTalentPortfolioPanel {...panelProps(PORTFOLIO)} config={config} />;
    }

    default:
      return null;
  }
};

CreateTalentWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  tabLabel: null,
};

const { bool, func, object, oneOf, shape, string } = PropTypes;

CreateTalentWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  locationSearch: object,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
  }).isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  tabLabel: string.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateProfile: func.isRequired,
  onEmailTaken: func.isRequired,
  onCreateProfileDraft: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  config: object.isRequired,
  routeConfiguration: arrayOf(propTypes.route).isRequired,
  isEmailTaken: bool.isRequired,
};

export default CreateTalentWizardTab;
