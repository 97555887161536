import React, { useState } from 'react';
import PropTypes, { bool, shape } from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { AspectRatioWrapper, IconArrowHead, ResponsiveImage, NamedLink } from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  showFullscreenButton: false,
};
const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

const ItemWrapper = props => {
  const { linkParams, children } = props;

  return linkParams ? (
    <NamedLink name="ListingPage" params={linkParams}>
      {children}
    </NamedLink>
  ) : (
    children
  );
};

const ListingImageGallery = props => {
  const { intl, rootClassName, className, images, imageVariants, linkParams } = props;

  // imageVariants are scaled variants.
  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const aspectWidthNum = props.aspectWidth ? props.aspectWidth : aspectWidth || 1;
  const aspectHeightNum = props.aspectHeight ? props.aspectHeight : aspectHeight || 1;

  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  const renderItem = item => {
    return (
      <ItemWrapper linkParams={linkParams}>
        <AspectRatioWrapper
          className={css.itemWrapper}
          width={aspectWidthNum}
          height={aspectHeightNum}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
            />
          </div>
        </AspectRatioWrapper>
      </ItemWrapper>
    );
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead className={css.arrowIcon} direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead className={css.arrowIcon} direction="right" size="big" />
        </div>
      </button>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <ReactImageGallery
      additionalClass={classes}
      items={items}
      renderItem={renderItem}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      {...IMAGE_GALLERY_OPTIONS}
    />
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  isMobileLayout: false,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  isMobileLayout: bool.isRequired,
  linkParams: shape({
    id: string,
    slug: string,
    type: string,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
