import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import util modules
import { useConfiguration } from '../../../../context/configurationContext';
import { isSignupEmailTakenError } from '../../../../util/errors';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  ChangeableImageFromFile,
  FieldCheckboxGroup,
  FieldDropzone,
  FieldSelect,
  FieldTextInput,
  Form,
  SecondaryButton,
} from '../../../../components';
import IconPlus from '../../../SearchPage/IconPlus/IconPlus';

// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateTalentPortfolioForm.module.css';

const ACCEPT_IMAGES = 'image/gif, image/jpeg, image/png';
const TAB_LISTING_FIELD_IDS = ['availability', 'workplacePreference', 'openTo', 'newsletter'];
const MIN_IMAGES = 1;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { signupError, updateListingError } = fetchErrors || {};

  const signupErrorMessage = signupError ? (
    isSignupEmailTakenError(signupError) ? (
      <FormattedMessage id="CreateTalentPortfolioForm.signupFailedEmailAlreadyTaken" />
    ) : (
      <FormattedMessage id="CreateTalentPortfolioForm.signupFailed" />
    )
  ) : null;
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentPortfolioForm.updateFailed" />
  ) : null;

  if (signupErrorMessage) {
    return <p className={css.error}>{signupErrorMessage}</p>;
  } else if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }

  return null;
};

const CreateTalentPortfolioFormComponent = props => {
  const uploadedFileFromInitialValues = props.initialValues.profileImage
    ? props.initialValues.profileImage
    : null;
  const uploadedFilesFromInitialValues = props.initialValues.images
    ? props.initialValues.images
    : [];

  const [uploadedFile, setUploadedFile] = useState(uploadedFileFromInitialValues);
  const [files, setFiles] = useState(uploadedFilesFromInitialValues);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          form,
          formId,
          values,
          intl,
          handleSubmit,
          onRedirectToPreviousTab,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const config = useConfiguration();
        const listingConfig = config.listing;
        const listingFields = listingConfig.listingFields.filter(l =>
          TAB_LISTING_FIELD_IDS.includes(l.key)
        );

        const availability = listingFields.find(l => l.key === 'availability');
        const availabilityOptions = availability ? availability.enumOptions : [];

        const workplacePreference = listingFields.find(l => l.key === 'workplacePreference');
        const workplacePreferenceOptions = workplacePreference
          ? workplacePreference.enumOptions
          : [];

        const openTo = listingFields.find(l => l.key === 'openTo');
        const openToOptions = openTo ? openTo.enumOptions : [];

        const newsletter = listingFields.find(l => l.key === 'newsletter');
        const newsletterOptions = newsletter ? newsletter.enumOptions : [];

        const imageId = uploadedFile && String(uploadedFile.lastModified);
        const imageFromFile = uploadedFile ? (
          <ChangeableImageFromFile
            id={imageId}
            rootClassName={css.imageFromFile}
            aspectWidth={1}
            aspectHeight={1}
            file={uploadedFile}
          />
        ) : null;

        const chooseLogoLabel = imageFromFile ? (
          <div className={css.imageFromFileWrapper}>
            {imageFromFile}
            <div className={css.imageFromFileLabelWrapper}>
              <span className={css.imageFromFileLabel}>
                <FormattedMessage id="CreateTalentPortfolioForm.changeLogoLabel" />
              </span>
            </div>
          </div>
        ) : (
          <div className={css.uploadZone}>
            <span className={css.uploadZoneLabel}>
              <FormattedMessage id="CreateTalentPortfolioForm.chooseLogoLabel" />
            </span>
            <span className={css.uploadZoneIconWrapper}>
              <IconPlus className={css.uploadZoneIcon} isOpen={false} />
            </span>
          </div>
        );

        const profileImageRequiredMessage = intl.formatMessage({
          id: 'CreateTalentPortfolioForm.profileImageRequired',
        });
        const profileImageRequired = validators.required(profileImageRequiredMessage);

        const publishProfileMessage = intl.formatMessage({
          id: 'CreateTalentPortfolioForm.publishProfile',
        });

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'CreateTalentBasicsForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'CreateTalentBasicsForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const confirmPasswordRequiredMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.confirmPasswordRequired',
        });
        const confirmPasswordInvalidMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.confirmPasswordInvalid',
        });
        const confirmPasswordRequired = validators.required(confirmPasswordRequiredMessage);
        const confirmPasswordInvalid = validators.passwordMatch(
          confirmPasswordInvalidMessage,
          values.password
        );
        const confirmPasswordValidators = validators.composeValidators(
          confirmPasswordRequired,
          confirmPasswordInvalid
        );

        const imagesFromValues = values.images;
        const hasEnoughImages = imagesFromValues?.length >= MIN_IMAGES;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <ErrorMessage fetchErrors={fetchErrors} />

            <div className={css.fieldsWrapper}>
              <div className={css.fieldContainer}>
                <span className={classNames(css.fieldLabel, css.fieldLabelWithMargin)}>
                  <FormattedMessage id="CreateTalentPortfolioForm.availabilityLabel" />
                </span>
                <div className={css.fieldFlex}>
                  <FieldSelect
                    id={`${formId}.availability`}
                    name="availability"
                    className={classNames(css.field, css.selectField)}
                    validate={validators.required(availability.saveConfig.requiredMessage)}
                    required
                  >
                    <option value="" disabled>
                      {availability.saveConfig.placeholderMessage}
                    </option>
                    {availabilityOptions.map(o => {
                      return (
                        <option key={o.option} value={o.option}>
                          {o.label}
                        </option>
                      );
                    })}
                  </FieldSelect>
                  <FieldSelect
                    id={`${formId}.openTo`}
                    name="openTo"
                    className={classNames(css.field, css.selectField)}
                    validate={validators.required(openTo.saveConfig.requiredMessage)}
                    required
                  >
                    <option value="" disabled>
                      {openTo.saveConfig.placeholderMessage}
                    </option>
                    {openToOptions.map(o => {
                      return (
                        <option key={o.option} value={o.option}>
                          {o.label.toLowerCase() === 'both' ? 'Either/or' : o.label + ' position'}
                        </option>
                      );
                    })}
                  </FieldSelect>
                </div>
              </div>

              <div className={css.fieldContainer}>
                <span className={classNames(css.fieldLabel, css.fieldLabelWithMargin)}>
                  <FormattedMessage id="CreateTalentPortfolioForm.workplacePreferenceLabel" />
                </span>
                <FieldCheckboxGroup
                  id={`${formId}.workplacePreference`}
                  name="workplacePreference"
                  className={classNames(css.field, css.selectField)}
                  options={workplacePreferenceOptions.map(o => ({ key: o.option, label: o.label }))}
                  validate={validators.nonEmptyArray(
                    workplacePreference.saveConfig.requiredMessage
                  )}
                />
              </div>

              <div className={css.fieldContainer}>
                <span className={css.fieldLabel}>
                  <FormattedMessage id="CreateTalentPortfolioForm.linksLabel" />
                </span>
                <div className={css.fieldLinkFlex}>
                  <FieldTextInput
                    id={`${formId}.website`}
                    name="website"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentPortfolioForm.websitePlaceholder',
                    })}
                    validate={validators.validURL(
                      intl.formatMessage({
                        id: 'CreateTalentPortfolioForm.websiteValid',
                      })
                    )}
                  />
                  <FieldTextInput
                    id={`${formId}.linkedin`}
                    name="linkedin"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentPortfolioForm.linkedinPlaceholder',
                    })}
                  />
                  <FieldTextInput
                    id={`${formId}.instagram`}
                    name="instagram"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentPortfolioForm.instagramPlaceholder',
                    })}
                    validate={validators.validInstagramLink(
                      intl.formatMessage({ id: 'CreateTalentPortfolioForm.instagramValid' })
                    )}
                  />
                </div>
              </div>

              <Field
                accept={ACCEPT_IMAGES}
                id={`${formId}.profileImage`}
                name="profileImage"
                label={chooseLogoLabel}
                type="file"
                form={null}
                validate={profileImageRequired}
              >
                {fieldProps => {
                  const { accept, id, input, label, disabled } = fieldProps;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    if (file) {
                      form.change(`profileImage`, file);
                      form.blur(`profileImage`);
                      setUploadedFile(file);
                    }
                  };

                  let error = null;

                  return (
                    <div className={css.fieldContainer}>
                      <span className={css.fieldLabel}>
                        <FormattedMessage id="CreateTalentPortfolioForm.profileLogoLabel" />
                      </span>
                      <p className={css.fieldText}>
                        <FormattedMessage id="CreateTalentPortfolioForm.profileLogoText" />
                      </p>
                      <label className={css.uploadLabel} htmlFor={id}>
                        {label}
                      </label>
                      <input
                        accept={accept}
                        id={id}
                        name={name}
                        className={css.uploadInput}
                        disabled={disabled}
                        onChange={onChange}
                        type={type}
                      />
                      {error}
                    </div>
                  );
                }}
              </Field>

              {/* <div className={css.fieldContainer}>
                <span className={css.fieldLabel}>
                  <FormattedMessage id="CreateTalentPortfolioForm.projectsLabel" />
                </span>
                <p className={css.fieldText}>
                  <FormattedMessage id="CreateTalentPortfolioForm.projectsText" />
                </p>
                <FieldDropzone
                  id={`${formId}.images`}
                  className={css.imagesField}
                  name="images"
                  files={files}
                  setFiles={setFiles}
                />
              </div> */}
            </div>
            <div className={css.passwordContainer}>
              <span className={css.fieldLabel}>Password</span>

              <div className={css.fieldFlex}>
                <FieldTextInput
                  className={css.field}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="password"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <FieldTextInput
                  className={css.field}
                  type="password"
                  id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                  name="confirmPassword"
                  autoComplete="password"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.confirmPasswordPlaceholder',
                  })}
                  validate={confirmPasswordValidators}
                />
              </div>
            </div>

            <div className={css.fieldContainer}>
              <FieldCheckboxGroup
                name="newsletter"
                id={`${formId}.newsletter`}
                className={css.newsLetter}
                optionLabelClassName={css.newsLetterfieldText}
                checkboxClassName={css.customCheckbox}
                options={[
                  {
                    key: 'newsletter-checked',
                    label: intl.formatMessage({ id: 'CreateTalentPortfolioForm.newsLetter' }),
                  },
                ]}
              />
            </div>

            <PanelFooter className={css.footer} onRedirectToPreviousTab={onRedirectToPreviousTab}>
              <SecondaryButton
                className={css.submitButton}
                type="submit"
                // inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {submitInProgress ? publishProfileMessage : saveActionMsg}
              </SecondaryButton>
            </PanelFooter>
          </Form>
        );
      }}
    />
  );
};

CreateTalentPortfolioFormComponent.defaultProps = {
  className: null,
  formId: 'CreateTalentPortfolioForm',
  fetchErrors: null,
};

CreateTalentPortfolioFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateTalentPortfolioFormComponent);
