import React, { Component } from 'react';
import { arrayOf, func, number, object, oneOf, shape, string, bool } from 'prop-types';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

// Import configs and util modules
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import { withViewport } from '../../../util/uiHelpers';
import { propTypes } from '../../../util/types';
import { LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPES } from '../../../util/urlHelpers';

// Import shared components
import { NamedRedirect, Tabs, IconSpinner } from '../../../components';

// Import modules from this directory
import CreateTalentWizardTab, {
  STYLE,
  BASICS,
  WORK,
  SPECIFICS,
  PORTFOLIO,
} from './CreateTalentWizardTab';
import css from './CreateTalentWizard.module.css';

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
const TABS = [BASICS, WORK, SPECIFICS, STYLE, PORTFOLIO];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const PORTAL_ROOT_CONTAINER_ID = 'portal-root';

/**
 * Return translations for wizard tab: label and submit button.
 *
 * @param {Object} intl
 * @param {string} tab name of the tab/panel in the wizard
 * @param {boolean} isNewListingFlow
 * @param {string} processName
 */
const tabLabelAndSubmit = (intl, tab, isNewListingFlow) => {
  let labelKey = null;
  let submitButtonKey = null;

  if (tab === BASICS) {
    labelKey = 'CreateTalentWizard.tabLabelBasics';
    submitButtonKey = isNewListingFlow
      ? `CreateTalentWizard.saveBasics`
      : `CreateTalentWizard.editBasics`;
  } else if (tab === WORK) {
    labelKey = 'CreateTalentWizard.tabLabelWork';
    submitButtonKey = isNewListingFlow
      ? `CreateTalentWizard.saveWork`
      : `CreateTalentWizard.editWork`;
  } else if (tab === SPECIFICS) {
    labelKey = 'CreateTalentWizard.tabLabelSpecifics';
    submitButtonKey = isNewListingFlow
      ? `CreateTalentWizard.saveSpecifics`
      : `CreateTalentWizard.editSpecifics`;
  } else if (tab === STYLE) {
    labelKey = 'CreateTalentWizard.tabLabelStyle';
    submitButtonKey = isNewListingFlow
      ? `CreateTalentWizard.saveStyle`
      : `CreateTalentWizard.editStyle`;
  } else if (tab === PORTFOLIO) {
    labelKey = 'CreateTalentWizard.tabLabelPortfolio';
    submitButtonKey = isNewListingFlow
      ? `CreateTalentWizard.savePortfolio`
      : `CreateTalentWizard.editPortfolio`;
  }

  return {
    label: intl.formatMessage({ id: labelKey }),
    submitButton: intl.formatMessage({ id: submitButtonKey }),
  };
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing) => {
  switch (tab) {
    case BASICS:
      return true;
    case WORK:
      return true;
    case SPECIFICS:
      return true;
    case STYLE:
      return true;
    case PORTFOLIO:
      return true;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 * @param tabs array of tabs used for this listing. These depend on transaction process.
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNew, listing, tabs, config) => {
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const validTab = previousTabIndex >= 0;
    const prevTabComletedInNewFlow = tabCompleted(tabs[previousTabIndex], listing, config);
    const isActive = validTab && isNew ? prevTabComletedInNewFlow : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create a new or edit listing through CreateTalentWizard
class CreateTalentWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      transactionProcessAlias: null,
    };
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(data) {
    const { onPublishProfileDraft } = this.props;

    onPublishProfileDraft(data);
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateInProgress !== prevProps.updateInProgress) {
      const isLastTab = this.props.params.tab === TABS[TABS.length - 1];

      if (isLastTab && this.props.updateInProgress) {
        document.body.style.overflow = 'hidden';
      }
    }
  }

  componentWillUnmount() {
    const isLastTab = this.props.params.tab === TABS[TABS.length - 1];

    if (isLastTab) {
      document.body.style.overflow = 'unset';
    }
  }

  render() {
    const {
      // classes
      className,
      rootClassName,
      // UI
      id,
      config,
      params,
      viewport,
      errors,
      intl,
      routeConfiguration,
      onManageDisableScrolling,
      updateInProgress,
      // profile
      currentProfile,
      ...rest
    } = this.props;

    const selectedTab = params.tab;
    const isNewListingFlow = LISTING_PAGE_PARAM_TYPE_NEW === params.type;

    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);

    // For oudated draft listing, we don't show other tabs but the "basics"
    const tabs = TABS;
    const isLastTab = selectedTab === tabs[tabs.length - 1];

    const publishInProgress = isLastTab && updateInProgress;

    // Check if wizard tab is active / linkable.
    // When creating a new listing, we don't allow users to access next tab until the current one is completed.
    const tabsStatus = tabsActive(isNewListingFlow, currentProfile, tabs, config);

    // Redirect user to first tab when encoutering outdated draft listings.
    if (isNewListingFlow && selectedTab !== tabs[0]) {
      return <NamedRedirect name="CreateTalentPage" params={{ ...params, tab: tabs[0] }} />;
    } else if (!isNewListingFlow && !currentProfile) {
      return (
        <NamedRedirect
          name="CreateTalentPage"
          params={{ ...params, type: LISTING_PAGE_PARAM_TYPE_NEW, tab: tabs[0] }}
        />
      );
    }

    // If selectedTab is not active for listing with valid listing type,
    // redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs
        .slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      console.log(
        `You tried to access an CreateTalentWizard tab (${selectedTab}), which was not yet activated.`
      );
      return (
        <NamedRedirect name="CreateTalentPage" params={{ ...params, tab: nearestActiveTab }} />
      );
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: 'CreateTalentPage', params: { ...params, tab } };
    };

    return (
      <div className={classes}>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
        >
          {tabs.map(tab => {
            const tabTranslations = tabLabelAndSubmit(intl, tab, isNewListingFlow);
            return (
              <CreateTalentWizardTab
                {...rest}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabTranslations.label}
                tabSubmitButtonText={tabTranslations.submitButton}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                params={params}
                currentProfile={currentProfile}
                marketplaceTabs={tabs}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                onProcessChange={transactionProcessAlias =>
                  this.setState({ transactionProcessAlias })
                }
                onManageDisableScrolling={onManageDisableScrolling}
                updateInProgress={updateInProgress}
                config={config}
                routeConfiguration={routeConfiguration}
              />
            );
          })}
        </Tabs>
        {publishInProgress &&
          createPortal(
            <div className={css.publishInProgress}>
              <div className={css.publishInProgressContent}>
                <IconSpinner className={css.publishInProgressIcon} />
                <span className={css.publishInProgressText}>
                  <FormattedMessage id="CreateCompanyWizard.publishInProgress" />
                </span>
              </div>
            </div>,
            document.getElementById(PORTAL_ROOT_CONTAINER_ID)
          )}
      </div>
    );
  }
}

CreateTalentWizard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

CreateTalentWizard.propTypes = {
  id: string.isRequired,
  className: string,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(TABS).isRequired,
  }).isRequired,
  currentProfile: object,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      price: object,
      title: string,
    }),
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
  }).isRequired,

  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedCreateTalentWizard = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <CreateTalentWizard
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

export default withViewport(EnhancedCreateTalentWizard);
