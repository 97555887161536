import React from 'react';

import { FormattedMessage } from 'react-intl';
import InfoSVG from '../../assets/info.svg';
import {
  FieldCheckboxGroup,
  FieldSelect,
  FieldTextInput
} from '../../components';
import FieldCheckboxGroupCustom from '../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';
import * as validators from '../../util/validators';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';


const TalentSignUpSpecificsForm = props => {
  const {
    sectionId,
    formId,
    intl,
    titleRequired,
    companyRequired,
    universityRequired,
    graduationYear,
    graduationYearOptions,
    licensesAndCertification,
    licensesAndCertificationOptions,
    yearsOfExperience,
    yearsOfExperienceOptions,
    salaryRequirements,
    salaryRequirementsOptions,
    values,
  } = props;
  return (
    <TalentFormContent id={sectionId} title={intl.formatMessage({
      id: 'TalentSignUpPage.TalentSignUpSpecificsForm.title',
    })}>
      <div className={css.fieldsWrapper}>
        <div className={css.fieldContainer}>
          <label className={css.fieldLabelwithToolTip}>
            <FormattedMessage id="CreateTalentSpecificsForm.titleLabel" />
            <div className={css.tooltip}>
              <img src={InfoSVG} width={12} height={12} />
              <span className={css.tooltiptext}>
                <FormattedMessage id="CreateTalentSpecificsForm.ToolTip" />
              </span>
            </div>
          </label>
          <div className={css.fieldFlex}>
            <FieldTextInput
              id={`${formId}.title`}
              name="title"
              className={css.field}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentSpecificsForm.titlePlaceholder',
              })}
              validate={titleRequired}
            />
            <FieldTextInput
              id={`${formId}.company`}
              name="company"
              className={css.field}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentSpecificsForm.companyPlaceholder',
              })}
              validate={companyRequired}
            />
          </div>
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateTalentSpecificsForm.educationLabel" />
          </label>
          <div className={css.fieldFlex}>
            <FieldTextInput
              id={`${formId}.university`}
              name="university"
              className={css.field}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentSpecificsForm.universityPlaceholder',
              })}
              validate={universityRequired}
            />
            <FieldTextInput
              id={`${formId}.degree`}
              name="degree"
              className={css.field}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentSpecificsForm.degreePlaceholder',
              })}
            />
            <FieldSelect
              id={`${formId}.graduationYear`}
              name="graduationYear"
              className={css.field}
              validate={validators.required(graduationYear.saveConfig.requiredMessage)}
              required
            >
              <option disabled value="">
                {graduationYear.saveConfig.placeholderMessage}
              </option>
              {graduationYearOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            <label className={css.fieldLabel}>{licensesAndCertification.saveConfig.label}</label>
          </label>
          <div className={css.fieldFlex}>
            <FieldCheckboxGroupCustom
              className={css.checkboxFields}
              id={`${formId}.${licensesAndCertification.key}`}
              name={licensesAndCertification.key}
              options={licensesAndCertificationOptions.map(o => ({
                key: o.option,
                label: o.label,
              }))}
              displayAsRow
            />
          </div>
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateTalentSpecificsForm.experienceLabel" />
          </label>

          <div className={css.fieldFlex}>
            <FieldSelect
              id={`${formId}.experience`}
              name="experience"
              className={css.field}
              validate={validators.required(yearsOfExperience.saveConfig.requiredMessage)}
              required
            >
              <option disabled value="">
                {yearsOfExperience.saveConfig.placeholderMessage}
              </option>
              {yearsOfExperienceOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
            <div className={css.field}>
              <label className={css.experienceLabel}>{salaryRequirements.saveConfig.label}</label>
              <FieldCheckboxGroup
                id={`${formId}.salaryRequirements`}
                name="salaryRequirements"
                className={css.field}
                options={salaryRequirementsOptions.map(o => ({
                  key: o.option,
                  label: o.label,
                }))}
                twoColumns
              />
            </div>
          </div>
        </div>
      </div>
    </TalentFormContent>
  );
};

export default TalentSignUpSpecificsForm;
