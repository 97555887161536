import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { LinkedLogo, NamedLink } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';

import css from './Footer.module.css';

const Footer = props => {
  const { rootClassName, className, intl } = props;

  const config = useConfiguration();
  const marketplaceName = config.marketplaceName;

  const contactLink = (
    <NamedLink name="ContactPage" className={css.navigationLink}>
      <FormattedMessage id="Footer.contact" />
    </NamedLink>
  );

  const privacyPolicyLink = (
    <NamedLink name="PrivacyPolicyPage" className={classNames(css.navigationLink, css.legalLink)}>
      <FormattedMessage id="Footer.privacyPolicy" />
    </NamedLink>
  );

  const termsOfServiceLink = (
    <NamedLink name="TermsOfServicePage" className={classNames(css.navigationLink, css.legalLink)}>
      <FormattedMessage id="Footer.termsOfService" />
    </NamedLink>
  );

  const siteCreditsLink = (
    <NamedLink name="SiteCreditsPage" className={classNames(css.navigationLink, css.legalLink)}>
      <FormattedMessage id="Footer.siteCredits" />
    </NamedLink>
  );

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LinkedLogo
        className={css.logo}
        format="desktop"
        alt={intl.formatMessage({ id: 'Footer.logo' }, { marketplaceName })}
      />
      <div className={css.navigationLinks}>
        {contactLink}
        {/* {privacyPolicyLink}
        {termsOfServiceLink}
        {siteCreditsLink} */}
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
