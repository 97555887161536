import { fetchCurrentUser } from '../../ducks/user.duck';
import { integrationAPI } from '../../util/api';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_TYPE_TALENT } from '../../util/types';
import { allowBodyScroll } from '../../util/uiHelpers';
import { clearProfileData as clearTalentProfileData } from '../CreateTalentPage/CreateTalentPage.duck';
import { clearProfileData as clearCompanyProfileData } from '../CreateCompanyPage/CreateCompanyPage.duck';

export const TALENT_LISTING_APPROVAL_DELAY = 3000;

export const approveListing = () => (dispatch, getState, sdk) => {
  const { currentUserListing } = getState().user;
  const { publicData, state } = currentUserListing?.attributes || {};
  const listingId = currentUserListing?.id?.uuid;
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;

  // Ensure that the listing indeed have the talent type
  // in extended data before approving the listing.
  const type = publicData?.type;
  const isTalentType = type === LISTING_TYPE_TALENT;

  if (isTalentType && isPendingApproval) {
    return integrationAPI.listings
      .approve({
        id: listingId,
      })
      .then(async response => {
        // Fetch current user so that the state is updated.
        await dispatch(fetchCurrentUser());

        console.log(`Listing with id ${listingId} has been approved.`);
        return response;
      })
      .catch(e => `Error approving listing with id ${listingId}.`);
  }
};

export const loadData = params => (dispatch, getState, sdk) => {
  // Body scroll is disabled on the previous page, so we need to
  // enbale it when user is redirected to ProfileCompletePage.
  allowBodyScroll();

  const isTalentType = params.type === LISTING_TYPE_TALENT;

  if (isTalentType) {
    return dispatch(fetchCurrentUser()).then(() => {
      dispatch(clearTalentProfileData());

      setTimeout(() => {
        // dispatch(approveListing());
      }, [TALENT_LISTING_APPROVAL_DELAY]);
    });
  } else {
    return Promise.all([dispatch(fetchCurrentUser()), dispatch(clearCompanyProfileData())]);
  }
};
