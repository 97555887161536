import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { LISTING_TYPE_COMPANY, LISTING_TYPE_TALENT, propTypes } from '../../../util/types';
import { isCurrentUserAdmin } from '../../../util/data';
import { InlineTextButton, LinkedLogo, NamedLink } from '../../../components';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    rootClassName,
    intl,
    currentUser,
    isAuthenticated,
    isLandingPage,
    isAboutPage,
    isCompanyUser,
    onLogout,
    onOpenHeroBoxesModal,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;

  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isUserAdmin = isCurrentUserAdmin(currentUser);

  const classes = classNames(rootClassName || css.root, className);

  const aboutLink = isAboutPage ? null : (
    <NamedLink name="AboutPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.about" />
    </NamedLink>
  );

  const contactLink = (
    <NamedLink name="ContactPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.contact" />
    </NamedLink>
  );

  const exportLink = isUserAdmin ? (
    <NamedLink name="ExportPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.export" />
    </NamedLink>
  ) : null;

  const accountLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink name="ProfileSettingsPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.account" />
    </NamedLink>
  ) : null;

  const directoryLink = isAuthenticatedOrJustHydrated ? (
    <NamedLink
      name="SearchPage"
      className={css.navigationLink}
      params={{ type: isCompanyUser ? LISTING_TYPE_TALENT : LISTING_TYPE_COMPANY }}
    >
      <FormattedMessage id="TopbarDesktop.directory" />
    </NamedLink>
  ) : null;
  const directoryLinkAsButton = isAuthenticatedOrJustHydrated ? null : (
    <InlineTextButton className={css.navigationLink} onClick={onOpenHeroBoxesModal}>
      <FormattedMessage id="TopbarDesktop.directory" />
    </InlineTextButton>
  );

  const logoutLink = isAuthenticatedOrJustHydrated ? (
    <InlineTextButton onClick={onLogout} className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.logout" />
    </InlineTextButton>
  ) : null;

  // const signupLink = isAuthenticatedOrJustHydrated ? null : isLandingPage ? (
  //   <InlineTextButton className={css.navigationLink} onClick={onOpenHeroBoxesModal}>
  //     <FormattedMessage id="TopbarDesktop.signup" />
  //   </InlineTextButton>
  // ) : (
  //   <NamedLink name="LandingPage" className={css.navigationLink}>
  //     <FormattedMessage id="TopbarDesktop.signup" />
  //   </NamedLink>
  // );

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <InlineTextButton className={css.navigationLink} onClick={onOpenHeroBoxesModal}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </InlineTextButton>
  )


  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.navigationLink}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  const logo = isLandingPage ? (
    <span className={css.logoText}>
      <FormattedMessage id="TopbarDesktop.logoText" />
    </span>
  ) : (
    <LinkedLogo
      className={css.logoLink}
      format="desktop"
      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
    />
  );

  return (
    <nav className={classes}>
      <div className={css.leftNavigation}>
        {aboutLink}
        {contactLink}
        {exportLink}
        {/* {directoryLinkAsButton} */}
      </div>

      {logo}

      <div className={css.rightNavigation}>
        {/* {directoryLink} */}
        {accountLink}
        {logoutLink}
        {signupLink}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  appConfig: null,
  currentUser: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  isLandingPage: bool.isRequired,
  isAboutPage: bool.isRequired,
  isCompanyUser: bool.isRequired,
  onLogout: func.isRequired,
  onOpenHeroBoxesModal: func.isRequired,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
