import classNames from 'classnames';
import { array, bool, func, object, string } from 'prop-types';
import React from 'react';

// Import util modules
import { FormattedMessage } from '../../../../util/reactIntl';

// Import shared components
import { Heading } from '../../../../components';

// Import modules from this directory
import { useDispatch } from 'react-redux';
import { updateorcreateMailChimpMember } from '../../CreateTalentPage.duck';
import PanelHeader from '../PanelHeader';
import CreateTalentSpecificsForm from './CreateTalentSpecificsForm';
import css from './CreateTalentSpecificsPanel.module.css';

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateTalentSpecificsPanel = props => {
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();
  const { email } = currentProfile || {};

  const classes = classNames(rootClassName || css.root, className);

  const experienceInitialValue = currentProfile?.experience
    ? { experience: currentProfile?.experience[0] }
    : {};
  const graduationYearInitialValue = currentProfile?.graduationYear
    ? { graduationYear: currentProfile.graduationYear[0] }
    : {};
  const initialValues = currentProfile
    ? { ...currentProfile, ...experienceInitialValue, ...graduationYearInitialValue }
    : {};
  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateTalentSpecificsPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateTalentSpecificsForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            title,
            company,
            university,
            degree,
            graduationYear,
            favoriteDesignFirm,
            experience,
            salaryRequirements,
            licensesAndCertification,
          } = values;

          const updateValues = {
            title,
            company,
            university,
            degree,
            graduationYear: [graduationYear],
            favoriteDesignFirm,
            experience: [experience],
            salaryRequirements,
            licensesAndCertification,
          };

          const mailchimpMember = {
            email,
            step: 3,
            registertype: 'Candidates',
          };

          dispatch(updateorcreateMailChimpMember(mailchimpMember));

          onSubmit(updateValues);
        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
      />
    </div>
  );
};

CreateTalentSpecificsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateTalentSpecificsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

export default CreateTalentSpecificsPanel;
