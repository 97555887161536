import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { useConfiguration } from '../../../../context/configurationContext';
import * as validators from '../../../../util/validators';

// Import shared components
import {
  Form,
  SecondaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckboxGroup,
} from '../../../../components';
// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateTalentSpecificsForm.module.css';
import InfoSVG from '../../../../assets/info.svg';
import FieldCheckboxGroupCustom from '../../../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';

const TAB_LISTING_FIELD_IDS = ['graduationYear', 'experience', 'salaryRequirements','licensesAndCertification'];

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentSpecificsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="CreateTalentSpecificsForm.createListingDraftError" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

const CreateTalentSpecificsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        onRedirectToPreviousTab,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({
        id: 'CreateTalentSpecificsForm.titleRequired',
      });
      const titleRequired = validators.required(titleRequiredMessage);

      const companyRequiredMessage = intl.formatMessage({
        id: 'CreateTalentSpecificsForm.companyRequired',
      });
      const companyRequired = validators.required(companyRequiredMessage);

      const universityRequiredMessage = intl.formatMessage({
        id: 'CreateTalentSpecificsForm.universityRequired',
      });
      const universityRequired = validators.required(universityRequiredMessage);

      const config = useConfiguration();
      const listingConfig = config.listing;
      const listingFields = listingConfig.listingFields.filter(l =>
        TAB_LISTING_FIELD_IDS.includes(l.key)
      );

      const graduationYear = listingFields.find(l => l.key === 'graduationYear');
      const graduationYearOptions = graduationYear ? graduationYear.enumOptions : [];

      const yearsOfExperience = listingFields.find(l => l.key === 'experience');
      const yearsOfExperienceOptions = yearsOfExperience ? yearsOfExperience.enumOptions : [];

      const salaryRequirements = listingFields.find(l => l.key === 'salaryRequirements');
      const salaryRequirementsOptions = salaryRequirements ? salaryRequirements.enumOptions : [];
      
      const licensesAndCertification =listingFields.find(l => l.key === 'licensesAndCertification'); 
      const licensesAndCertificationOptions = licensesAndCertification ? licensesAndCertification.enumOptions : [];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <div className={css.fieldsWrapper}>
            <div className={css.fieldContainer}>
              <label className={css.fieldLabelwithToolTip}>
                  <FormattedMessage id="CreateTalentSpecificsForm.titleLabel" />
                <div className={css.tooltip}>
                  <img src={InfoSVG} width={12} height={12} />
                  <span className={css.tooltiptext}><FormattedMessage id='CreateTalentSpecificsForm.ToolTip'/></span>
                </div>
              </label>
              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.title`}
                  name="title"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentSpecificsForm.titlePlaceholder',
                  })}
                  validate={titleRequired}
                />
                <FieldTextInput
                  id={`${formId}.company`}
                  name="company"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentSpecificsForm.companyPlaceholder',
                  })}
                  validate={companyRequired}
                />
              </div>
            </div>

            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
                <FormattedMessage id="CreateTalentSpecificsForm.educationLabel" />
              </label>
              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.university`}
                  name="university"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentSpecificsForm.universityPlaceholder',
                  })}
                  validate={universityRequired}
                />
                <FieldTextInput
                  id={`${formId}.degree`}
                  name="degree"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentSpecificsForm.degreePlaceholder',
                  })}
                />
                <FieldSelect
                  id={`${formId}.graduationYear`}
                  name="graduationYear"
                  className={css.field}
                  validate={validators.required(graduationYear.saveConfig.requiredMessage)}
                  required
                >
                  <option disabled value="">
                    {graduationYear.saveConfig.placeholderMessage}
                  </option>
                  {graduationYearOptions.map(o => {
                    return (
                      <option key={o.option} value={o.option}>
                        {o.label}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>
            </div>

            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
              <label className={css.fieldLabel}>{licensesAndCertification.saveConfig.label}</label>
              </label>
              <div className={css.fieldFlex}>
              <FieldCheckboxGroupCustom
                className={css.checkboxFields}
                id={`${formId}.${licensesAndCertification.key}`}
                name={licensesAndCertification.key}
                options={licensesAndCertificationOptions.map(o => ({ key: o.option, label: o.label }))}
                // validate={validators.nonEmptyArray(program.saveConfig.requiredMessage)}
                displayAsRow
              />
              </div>
            </div>

            {/* <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
                <FormattedMessage id="CreateTalentSpecificsForm.favoriteDesignFirmLabel" />
              </label>
              <FieldTextInput
                id={`${formId}.favoriteDeisgnFirm`}
                name="favoriteDesignFirm"
                className={css.field}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'CreateTalentSpecificsForm.favoriteDesignFirmPlaceholder',
                })}
              />
            </div> */}

            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
                <FormattedMessage id="CreateTalentSpecificsForm.experienceLabel" />
              </label>

              <div className={css.fieldFlex}>
                <FieldSelect
                  id={`${formId}.experience`}
                  name="experience"
                  className={css.field}
                  validate={validators.required(yearsOfExperience.saveConfig.requiredMessage)}
                  required
                >
                  <option disabled value="">
                    {yearsOfExperience.saveConfig.placeholderMessage}
                  </option>
                  {yearsOfExperienceOptions.map(o => {
                    return (
                      <option key={o.option} value={o.option}>
                        {o.label}
                      </option>
                    );
                  })}
                </FieldSelect>
                <div className={css.field}>
                  <label className={css.experienceLabel}>
                    {salaryRequirements.saveConfig.label}
                  </label>
                  <FieldCheckboxGroup
                    id={`${formId}.salaryRequirements`}
                    name="salaryRequirements"
                    className={css.field}
                    options={salaryRequirementsOptions.map(o => ({
                      key: o.option,
                      label: o.label,
                    }))}
                    twoColumns
                  />
                </div>
              </div>
            </div>
          </div>

          <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
            <SecondaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </SecondaryButton>
          </PanelFooter>
        </Form>
      );
    }}
  />
);

CreateTalentSpecificsFormComponent.defaultProps = {
  className: null,
  formId: 'CreateTalentSpecificsForm',
  fetchErrors: null,
};

CreateTalentSpecificsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateTalentSpecificsFormComponent);
