import { storableError } from '../util/errors';
import { googleStorageAPI } from '../util/api';

// ================ Action types ================ //

export const UPLOAD_DOCUMENT_REQUEST = 'app/googleStorage/UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'app/googleStorage/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'app/googleStorage/UPLOAD_DOCUMENT_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/googleStorage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/googleStorage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/googleStorage/UPLOAD_IMAGE_ERROR';

export const UPLOAD_VIDEO_REQUEST = 'app/googleStorage/UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = 'app/googleStorage/UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_ERROR = 'app/googleStorage/UPLOAD_VIDEO_ERROR';

// ================ Reducer ================ //

const initialState = {
  uploadDocumentInProgress: false,
  uploadDocumentError: null,
  uploadImageInProgress: false,
  uploadImageErorr: null,
  uploadVideosInProgress: false,
  uploadVideoError: null,
};

export default function googleStorageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadDocumentInProgress: true,
        uploadDocumentError: null,
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocumentInProgress: false,
        uploadDocumentError: null,
      };
    case UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        uploadDocumentInProgress: false,
        uploadDocumentError: payload,
      };

    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadImageInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        uploadImageInProgress: false,
        uploadImageError: payload,
      };

    case UPLOAD_VIDEO_REQUEST:
      return {
        ...state,
        uploadVideoInProgress: true,
        uploadVideoError: null,
      };
    case UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadVideoInProgress: false,
        uploadVideoError: null,
      };
    case UPLOAD_VIDEO_ERROR:
      return {
        ...state,
        uploadVideoInProgress: false,
        uploadVideoError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const uploadDocumentRequest = () => ({ type: UPLOAD_DOCUMENT_REQUEST });
export const uploadDocumentSuccess = () => ({ type: UPLOAD_DOCUMENT_SUCCESS });
export const uploadDocumentError = error => ({
  type: UPLOAD_DOCUMENT_ERROR,
  error: true,
  payload: error,
});

export const uploadImageRequest = () => ({ type: UPLOAD_IMAGE_REQUEST });
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  error: true,
  payload: error,
});

export const uploadVideoRequest = () => ({ type: UPLOAD_VIDEO_REQUEST });
export const uploadVideoSuccess = () => ({ type: UPLOAD_VIDEO_SUCCESS });
export const uploadVideoError = error => ({
  type: UPLOAD_VIDEO_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const uploadDocument = document => async (dispatch, getState, sdk) => {
  dispatch(uploadDocumentRequest());

  // We need to create new FormData and
  // append the file to it, because the
  // SDK doesn't support file uploads.
  const formData = new FormData();
  formData.append('document', document);

  try {
    const response = await googleStorageAPI.documents.upload(formData);

    dispatch(uploadDocumentSuccess(response));
    return response;
  } catch (e) {
    dispatch(uploadDocumentError(storableError(e)));
  }
};

export const uploadImage = image => async (dispatch, getState, sdk) => {
  dispatch(uploadImageRequest());

  // We need to create new FormData and
  // append the image to it, because the
  // SDK doesn't support uploading images
  // outside of listings
  const formData = new FormData();
  formData.append('image', image);

  try {
    const response = await googleStorageAPI.images.upload(formData);

    dispatch(uploadImageSuccess(response));
    return response;
  } catch (e) {
    dispatch(uploadImageError(storableError(e)));
  }
};

export const uploadVideo = video => async (dispatch, getState, sdk) => {
  dispatch(uploadVideoRequest());

  const formData = new FormData();
  formData.append('video', video);

  try {
    const response = await googleStorageAPI.videos.upload(formData);

    dispatch(uploadVideoSuccess(response));
    return response;
  } catch (e) {
    dispatch(uploadVideoError(storableError(e)));
  }
};
