import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHome.module.css';

const IconHome = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        stroke="#B60101"
        strokeWidth="0.989"
        d="M8.84 12.784h-.494v6.678H1.318V7.714L11.004.793l9.683 6.92v11.75H13.66v-6.679H8.84z"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconHome.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHome.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHome;
