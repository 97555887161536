import { storableError } from '../../util/errors';
import { exportAPI } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ExportPage/SET_INITIAL_STATE';

export const EXPORT_DATA_REQUEST = 'app/ExportPage/EXPORT_DATA_REQUEST';
export const EXPORT_DATA_SUCCESS = 'app/ExportPage/EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_ERROR = 'app/ExportPage/EXPORT_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  exportedData: [],
  exportDataInProgress: false,
  exportDataError: null,
  isDataExported: false,
};

export default function exportPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case EXPORT_DATA_REQUEST:
      return {
        ...state,
        exportDataInProgress: true,
        exportDataError: null,
        isDataExported: false,
      };
    case EXPORT_DATA_SUCCESS:
      return {
        ...state,
        exportedData: payload.exportedData,
        exportDataInProgress: false,
        isDataExported: true,
      };
    case EXPORT_DATA_ERROR:
      return {
        ...state,
        exportDataInProgress: false,
        exportDataError: payload,
        isDataExported: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const exportDataRequest = () => ({
  type: EXPORT_DATA_REQUEST,
});

export const exportDataSuccess = data => ({
  type: EXPORT_DATA_SUCCESS,
  payload: { exportedData: data },
});

export const exportDataError = e => ({
  type: EXPORT_DATA_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const exportData = () => (dispatch, getState, sdk) => {
  dispatch(exportDataRequest());

  return exportAPI
    .users()
    .then(response => {
      dispatch(exportDataSuccess(response));
      return response; // data
    })
    .catch(e => dispatch(exportDataError(storableError(e))));
};
