import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const UPDATE_LISTING_REQUEST = 'app/EditProfilePage/UPDATE_LISTING_REQUEST';
export const UPDATE_LISTING_SUCCESS = 'app/EditProfilePage/UPDATE_LISTING_SUCCESS';
export const UPDATE_LISTING_ERROR = 'app/EditProfilePage/UPDATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateListingInProgress: false,
  updateListingError: null,
  updated: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        updateListingInProgress: true,
        updateListingError: null,
        updated: false,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        updateListingInProgress: false,
        updated: true,
      };
    case UPDATE_LISTING_ERROR:
      return {
        ...state,
        updateListingInProgress: false,
        updateListingError: payload,
        updated: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

// SDK method: sdk.ownListings.update
export const updateListingRequest = data => ({
  type: UPDATE_LISTING_REQUEST,
  payload: { data },
});
export const updateListingSuccess = response => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: { data: response.data },
});
export const updateListingError = error => ({
  type: UPDATE_LISTING_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

export const updateListing = data => {
  return (dispatch, getState, sdk) => {
    dispatch(updateListingRequest(data));

    return sdk.ownListings
      .update(data)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(updateListingSuccess(response));
        dispatch(fetchCurrentUser());

        return response;
      })
      .catch(e => dispatch(updateListingError(storableError(e))));
  };
};
