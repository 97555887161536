import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_SAVED_LISTINGS_REQUEST = 'app/InboxPage/FETCH_SAVED_LISTINGS_REQUEST';
export const FETCH_SAVED_LISTINGS_SUCCESS = 'app/InboxPage/FETCH_SAVED_LISTINGS_SUCCESS';
export const FETCH_SAVED_LISTINGS_ERROR = 'app/InboxPage/FETCH_SAVED_LISTINGS_ERROR';

export const REMOVE_SAVED_LISTING_REQUEST = 'app/InboxPage/REMOVE_SAVED_LISTING_REQUEST';
export const REMOVE_SAVED_LISTING_SUCCESS = 'app/InboxPage/REMOVE_SAVED_LISTING_SUCCESS';
export const REMOVE_SAVED_LISTING_ERROR = 'app/InboxPage/REMOVE_SAVED_LISTING_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  fetchSavedListingsInProgress: false,
  fetchSavedListingsError: null,
  savedListingRefs: [],
  removeSavedListingInProgress: false,
  removeSavedListingError: null,
  removeId: null,
  removeIds: [],
};

const resultIds = data =>
  data.map(l => ({
    id: l.id,
    type: l.type,
  }));

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_SAVED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchSavedListingsInProgress: true,
        fetchSavedListingsError: null,
      };
    case FETCH_SAVED_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchSavedListingsInProgress: false,
        fetchSavedListingsError: null,
        savedListingRefs: resultIds(payload.data),
      };
    case FETCH_SAVED_LISTINGS_ERROR:
      return {
        ...state,
        fetchSavedListingsInProgress: false,
        fetchSavedListingsError: payload,
      };

    case REMOVE_SAVED_LISTING_REQUEST:
      return {
        ...state,
        removeSavedListingInProgress: true,
        removeSavedListingError: null,
        removeId: payload.listingId,
        removeIds: [...state.removeIds, payload.listingId],
      };
    case REMOVE_SAVED_LISTING_SUCCESS:
      return {
        ...state,
        removeSavedListingInProgress: false,
        removeSavedListingError: null,
        removeId: null,
      };
    case REMOVE_SAVED_LISTING_ERROR:
      return {
        ...state,
        removeSavedListingInProgress: false,
        removeSavedListingError: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const fetchSavedListingsRequest = () => ({
  type: FETCH_SAVED_LISTINGS_REQUEST,
});
export const fetchSavedListingsSuccess = response => ({
  type: FETCH_SAVED_LISTINGS_SUCCESS,
  payload: { data: response.data.data },
});
export const fetchSavedListingsError = e => ({
  type: FETCH_SAVED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const removeSavedListingRequest = listingId => ({
  type: REMOVE_SAVED_LISTING_REQUEST,
  payload: { listingId },
});
export const removeSavedListingSuccess = () => ({
  type: REMOVE_SAVED_LISTING_SUCCESS,
});
export const removeSavedListingError = e => ({
  type: REMOVE_SAVED_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchSavedListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchSavedListingsRequest());

  return sdk.currentUser.show().then(response => {
    const currentUser = response.data.data;
    const currentUserSavedCompanyIds =
      currentUser?.attributes?.profile?.publicData?.savedCompanyIds || [];

    return sdk.listings
      .query({
        ids: currentUserSavedCompanyIds,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchSavedListingsSuccess(response));
        return response;
      })
      .catch(e => dispatch(fetchSavedListingsError(storableError(e))));
  });
};

export const removeSavedListing = listingId => (dispatch, getState, sdk) => {
  dispatch(removeSavedListingRequest(listingId));

  const { currentUser } = getState().user;
  const savedCompanyIds = currentUser?.attributes?.profile?.publicData?.savedCompanyIds;
  const updatedSavedCompanyIds = savedCompanyIds.filter(i => i !== listingId);

  return sdk.currentUser
    .updateProfile({
      publicData: {
        savedCompanyIds: updatedSavedCompanyIds,
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(removeSavedListingSuccess(response));
      return response;
    })
    .catch(e => dispatch(removeSavedListingError(storableError(e))));
};

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  if (tab === 'saved') {
    return dispatch(fetchSavedListings());
  } else {
    const onlyFilterValues = {
      orders: 'order',
      sales: 'sale',
    };

    const onlyFilter = onlyFilterValues[tab];
    if (!onlyFilter) {
      return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    }

    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const apiQueryParams = {
      only: onlyFilter,
      lastTransitions: getAllTransitionsForEveryProcess(),
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
      ],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
      perPage: INBOX_PAGE_SIZE,
    };

    return sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));

        return response;
      })
      .catch(e => {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      });
  }
};
