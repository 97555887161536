import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { required, nonEmptyArray } from '../../../../util/validators';

// Import shared components
import {
  Form,
  SecondaryButton,
  FieldTextInput,
  FieldSelect,
  FieldDropzone,
} from '../../../../components';

// Import modules from this directory
import { completedDateOptions } from './AddProjectForm.shared';
import css from './AddProjectForm.module.css';

/**
 * Form fields for adding a new project.
 * This component is extracted from the AddProjectForm and is also used as an EditProjectForm
 * in the parent component.
 */
export const FormFields = props => {
  const { formId, name, files, setFiles, intl } = props;

  // project name
  const projectNamePlaceholder = intl.formatMessage({
    id: 'AddProjectForm.projectNamePlaceholder',
  });
  const projectNameRequiredMessage = intl.formatMessage({
    id: 'AddProjectForm.projectNameRequired',
  });
  const projectNameRequired = required(projectNameRequiredMessage);

  // completed at
  const completedAtPlaceholder = intl.formatMessage({
    id: 'AddProjectForm.completedAtPlaceholder',
  });
  const completedAtRequiredMessage = intl.formatMessage({
    id: 'AddProjectForm.completedAtRequired',
  });
  const completedAtRequired = required(completedAtRequiredMessage);

  // completed date
  const completedDatePlaceholder = intl.formatMessage({
    id: 'AddProjectForm.completedDatePlaceholder',
  });
  const completedDateRequiredMessage = intl.formatMessage({
    id: 'AddProjectForm.completedDateRequired',
  });
  const completedDateRequired = required(completedDateRequiredMessage);

  return (
    <div className={css.fields}>
      <FieldTextInput
        className={css.field}
        id={`${formId}.projectName`}
        name={name ? `${name}.projectName` : 'projectName'}
        type="text"
        placeholder={projectNamePlaceholder}
        validate={projectNameRequired}
      />

      <div className={css.fieldFlex}>
        <FieldTextInput
          className={css.field}
          id={`${formId}.completedAt`}
          name={name ? `${name}.completedAt` : 'completedAt'}
          type="text"
          placeholder={completedAtPlaceholder}
          validate={completedAtRequired}
        />

        <FieldSelect
          className={css.field}
          id={`${formId}.completedDate`}
          name={name ? `${name}.completedDate` : 'completedDate'}
          validate={completedDateRequired}
          required
        >
          <option disabled value="">
            {completedDatePlaceholder}
          </option>
          {completedDateOptions.map(o => {
            return (
              <option key={o.key} value={o.key}>
                {o.label}
              </option>
            );
          })}
        </FieldSelect>
      </div>

      <FieldDropzone
        className={css.field}
        id={`${formId}.images`}
        name={name ? `${name}.images` : 'images'}
        files={files}
        setFiles={setFiles}
      />
    </div>
  );
};

const AddProjectFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const { className, handleSubmit, intl, formId, files, setFiles, invalid } = formRenderProps;

      const classes = classNames(css.root, className);
      const submitDisabled = invalid;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FormFields formId={formId} files={files} setFiles={setFiles} intl={intl} />

          <SecondaryButton className={css.submitButton} type="submit" disabled={submitDisabled}>
            <FormattedMessage id="AddProjectForm.submitButton" />
          </SecondaryButton>
        </Form>
      );
    }}
  />
);

AddProjectFormComponent.defaultProps = {
  className: null,
  formId: 'AddProjectForm',
  fetchErrors: null,
};

AddProjectFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(AddProjectFormComponent);
