import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureCompanySearchOrUserType, getUserType } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import classNames from 'classnames';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, currentUser, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const user = ensureCurrentUser(currentUser);
  const userType = getUserType(currentUser);
  const isCompanyUser = ensureCompanySearchOrUserType(userType);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profile" />,
      selected: currentPage === 'ProfileSettingsPage' || currentPage === 'EditProfilePage',
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    // {
    //   text: <FormattedMessage id="UserNav.connections" />,
    //   selected: currentPage === 'InboxPage',
    //   disabled: false,
    //   linkProps: {
    //     name: 'InboxPage',
    //     params: { tab: isCompanyUser ? 'orders' : 'sales' },
    //   },
    // },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.filter(
        p => p !== 'ProfileSettingsPage' && p !== 'EditProfilePage'
      ).includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'AccountSettingsPage',
      },
    },
  ];

  return <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} />;
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  return {
    currentUser: state.user.currentUser,
  };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
