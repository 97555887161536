import classNames from 'classnames';
import React, { useState } from 'react';

import { OnChange } from 'react-final-form-listeners';
import { FormattedMessage } from 'react-intl';
import { FieldSelect, FieldTextInput, InlineTextButton } from '../../components';
import * as validators from '../../util/validators';
import { LocationSearchField } from '../SearchPage/LocationFilter/LocationFilter';
import { CompanyFormContent } from './CompanySignUpPage';
import css from './CompanySignUpPage.module.css';

const NAME_MAX_LENGTH = 60;
const MAPBOX_MAP_CITY_TYPES = ['place'];

const CompanySignUpBasicForm = props => {
  const {
    sectionId,
    formId,
    intl,
    onEmailChangeWithDebounce,
    emailRequired,
    emailValid,
    values,
    initialValues,
    form,
    maxLength60Message,
    nameRequiredMessage,
    companySize,
    companySizeOptions,
    isMobileLayout,
  } = props;

  const hasSecondaryAddress = initialValues?.secondaryAddress;
  const [secondaryAddressVisible, setSecondaryAddressVisible] = useState(hasSecondaryAddress);

  return (
    <CompanyFormContent
      id={sectionId}
      title={intl.formatMessage({
        id: 'CompanytSignUpPage.CompanySignUpBasicForm.title',
      })}
    >
      <div className={css.fieldsWrapper}>
        <label>
          <FormattedMessage id="CreateCompanyBasicsForm.companyDetails" />
        </label>
        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.name`}
            name="name"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateCompanyBasicsForm.namePlaceholder',
            })}
            maxLength={NAME_MAX_LENGTH}
            validate={validators.composeValidators(
              validators.required(nameRequiredMessage),
              maxLength60Message
            )}
          />
          <FieldTextInput
            id={`${formId}.email`}
            name="email"
            className={css.field}
            type="email"
            placeholder={intl.formatMessage({
              id: 'CreateCompanyBasicsForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <OnChange name="email">
            {(value, previous) => onEmailChangeWithDebounce({ email: value })}
          </OnChange>
        </div>

        <div className={css.fieldFlex}>
          <FieldSelect
            id={`${formId}.companySize`}
            name="companySize"
            className={css.field}
            validate={validators.required(companySize.saveConfig.requiredMessage)}
            required
          >
            <option disabled value="">
              {companySize.saveConfig.placeholderMessage}
            </option>
            {companySizeOptions.map(o => {
              return (
                <option key={o.option} value={o.option}>
                  {o.label}
                </option>
              );
            })}
          </FieldSelect>
          <FieldTextInput
            id={`${formId}.website`}
            name="website"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateCompanyBasicsForm.websitePlaceholder',
            })}
          />
        </div>

        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.instagram`}
            name="instagram"
            className={classNames(css.field, css.instagramField)}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateCompanyBasicsForm.instagramPlaceholder',
            })}
            validate={validators.validInstagramLink(
              intl.formatMessage({ id: 'CreateCompanyBasicsForm.instagramInvalid' })
            )}
          />
        </div>

        <label>
          <FormattedMessage id="CreateCompanyBasicsForm.addressDetails" />
        </label>

        <LocationSearchField
          intl={intl}
          placeholder={intl.formatMessage({
            id: 'CreateCompanyBasicsForm.addressPlaceholder',
          })}
          isMobile={isMobileLayout}
          types={MAPBOX_MAP_CITY_TYPES}
          hideDefaultPredictions
          onLocationChange={() => {}}
        />

        {secondaryAddressVisible ? (
          <div className={css.secondaryAddress}>
            <label className={css.secondaryAddressLabel}>
              <FormattedMessage id="CreateCompanyBasicsForm.secondaryAddressDetails" />
            </label>
            <LocationSearchField
              intl={intl}
              placeholder={intl.formatMessage({
                id: 'CreateCompanyBasicsForm.addressPlaceholder',
              })}
              isMobile={isMobileLayout}
              types={MAPBOX_MAP_CITY_TYPES}
              hideDefaultPredictions
              onLocationChange={() => {}}
              fieldName="secondaryLocation"
            />
          </div>
        ) : null}

        {secondaryAddressVisible ? (
          <InlineTextButton
            type="button"
            className={classNames(css.secondaryAddressButton, css.deleteSecondaryAddressButton)}
            onClick={() => {
              setSecondaryAddressVisible(false);
              form.change('secondaryAddress', undefined);
            }}
          >
            <FormattedMessage id="CreateCompanyBasicsForm.deleteSecondaryAddressButton" />
          </InlineTextButton>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <InlineTextButton
              type="button"
              className={css.secondaryAddressButton}
              onClick={() => setSecondaryAddressVisible(true)}
            >
              <FormattedMessage id="CreateCompanyBasicsForm.secondaryAddressButton" />
            </InlineTextButton>
          </div>
        )}
      </div>
    </CompanyFormContent>
  );
};

export default CompanySignUpBasicForm;
