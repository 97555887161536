import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  fetchCurrentUser,
  currentUserShowSuccess,
  fetchCurrentUserHasListings,
} from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const SAVE_BIRTHDAY_REQUEST = 'app/BirthdayChangePage/SAVE_BIRTHDAY_REQUEST';
export const SAVE_BIRTHDAY_SUCCESS = 'app/BirthdayChangePage/SAVE_BIRTHDAY_SUCCESS';
export const SAVE_BIRTHDAY_ERROR = 'app/BirthdayChangePage/SAVE_BIRTHDAY_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveBirthdayInProgress: false,
  saveBirthdayError: null,
  birthdayChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_BIRTHDAY_REQUEST:
      return {
        ...state,
        saveBirthdayInProgress: true,
        saveBirthdayError: null,
        birthdayChanged: false,
      };
    case SAVE_BIRTHDAY_SUCCESS:
      return {
        ...state,
        saveBirthdayInProgress: false,
        saveBirthdayError: null,
        birthdayChanged: true,
      };
    case SAVE_BIRTHDAY_ERROR:
      return {
        ...state,
        saveBirthdayInProgress: false,
        saveBirthdayError: payload,
        birthdayChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveBirthdayRequest = () => ({ type: SAVE_BIRTHDAY_REQUEST });
export const saveBirthdaySuccess = () => ({ type: SAVE_BIRTHDAY_SUCCESS });
export const saveBirthdayError = error => ({
  type: SAVE_BIRTHDAY_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

const requestSaveBirthdayToListing = birthday => (dispatch, getState, sdk) => {
  const { currentUserListing } = getState().user;

  return sdk.ownListings
    .update({
      id: currentUserListing.id,
      publicData: {
        birthday,
      },
    })
    .then(response => {
      return response;
    })
    .catch(e => {
      dispatch(saveBirthdayError(storableError(e)));
      throw e;
    });
};

const saveBirthdayToListing = birthday => (dispatch, getState, sdk) => {
  return dispatch(requestSaveBirthdayToListing(birthday))
    .then(response => {
      return response;
    })
    .catch(e => null);
};

export const saveBirthday = birthday => (dispatch, getState, sdk) => {
  dispatch(saveBirthdayRequest());

  return dispatch(saveBirthdayToListing(birthday))
    .then(response => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(addMarketplaceEntities(response));
      dispatch(saveBirthdaySuccess(response));
      return response;
    })
    .catch(e => null);
};
