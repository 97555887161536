import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { InlineTextButton, Heading, LayoutSingleColumn, Footer } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import css from './LoadableComponentErrorBoundary.module.css';

export const LoadableComponentErrorBoundaryPage = () => {
  const routeConfiguration = useRouteConfiguration();
  const landingPagePath = pathByRouteName('LandingPage', routeConfiguration);
  const handleOnClick = () => {
    if (typeof window !== 'undefined') {
      window.location = landingPagePath;
    }
  };

  const landingPageLink = (
    <InlineTextButton className={css.link} onClick={handleOnClick}>
      <FormattedMessage id="LoadableComponentErrorBoundaryPage.landingPageLink" />
    </InlineTextButton>
  );

  return (
    <div>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<Footer />}>
        <div className={css.root}>
          <div className={css.content}>
            <div className={css.contentWrapper}>
              <div className={css.number}>404</div>
              <Heading as="h2" rootClassName={css.heading}>
                <FormattedMessage id="LoadableComponentErrorBoundaryPage.heading" />
              </Heading>
              <p className={css.description}>
                <FormattedMessage
                  id="LoadableComponentErrorBoundaryPage.description"
                  values={{ link: landingPageLink }}
                />
              </p>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </div>
  );
};
