import pick from 'lodash/pick';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import * as log from '../../util/log';
import { ensureCompanySearchOrUserType } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess, getProcess } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  exportAPI,
  getConnection,
  googleSpreadsheetAPI,
  sendConnectionEmail as sendConnectionEmailAPI,
} from '../../util/api';

const { UUID } = sdkTypes;

const TALENT_ASPECT_RATIO_WIDTH = 4;
const TALENT_ASPECT_RATIO_HEIGHT = 5;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const SEND_CONNECTION_EMIAL_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_CONNECTION_EMIAL_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_CONNECTION_EMIAL_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const SAVE_COMPANY_REQUEST = 'app/ListingPage/SAVE_COMPANY_REQUEST';
export const SAVE_COMPANY_SUCCESS = 'app/ListingPage/SAVE_COMPANY_SUCCESS';
export const SAVE_COMPANY_ERROR = 'app/ListingPage/SAVE_COMPANY_ERROR';

export const GET_CONNECTION_REQUEST = 'app/ListingPage/GET_CONNECTION_REQUEST';
export const GET_CONNECTION_SUCCESS = 'app/ListingPage/GET_CONNECTION_SUCCESS';
export const GET_CONNECTION_ERROR = 'app/ListingPage/GET_CONNECTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquirySubmitted: false,
  inquiryModalOpenForListingId: null,
  sendConnectionEmailInProgress: false,
  sendConnectionEmailError: null,
  saveCompanyInProgress: false,
  saveCompanyError: null,
  saveCompanySubmitted: false,
  isConnected:false,
  connectionLoading:false,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case SEND_INQUIRY_REQUEST:
      return {
        ...state,
        sendInquiryInProgress: true,
        sendInquiryError: null,
        inquirySubmitted: false,
      };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false, inquirySubmitted: true };
    case SEND_INQUIRY_ERROR:
      return {
        ...state,
        sendInquiryInProgress: false,
        sendInquiryError: payload,
        inquirySubmitted: false,
      };

    case SEND_CONNECTION_EMIAL_REQUEST:
      return {
        ...state,
        sendConnectionEmailInProgress: true,
        sendConnectionEmailError: null,
      };
    case SEND_CONNECTION_EMIAL_SUCCESS:
      return {
        ...state,
        sendConnectionEmailInProgress: false,
        sendConnectionEmailError: null,
      };
    case SEND_CONNECTION_EMIAL_ERROR:
      return {
        ...state,
        sendConnectionEmailInProgress: false,
        sendConnectionEmailError: payload,
      };

    case SAVE_COMPANY_REQUEST:
      return {
        ...state,
        saveCompanyInProgress: true,
        saveCompanyError: null,
        saveCompanySubmitted: false,
      };
    case SAVE_COMPANY_SUCCESS:
      return {
        ...state,
        saveCompanyInProgress: false,
        saveCompanyError: null,
        saveCompanySubmitted: true,
      };
    case SAVE_COMPANY_ERROR:
      return {
        ...state,
        saveCompanyInProgress: false,
        saveCompanyError: payload,
        saveCompanySubmitted: false,
      };

    case GET_CONNECTION_REQUEST:
      return {
        ...state,
        connectionLoading:true,
      };
    case GET_CONNECTION_SUCCESS:
      return {
        ...state,
        isConnected:payload.isConnected,
        connectionLoading:false,
      };
    case GET_CONNECTION_ERROR:
      return {
        ...state,
        connectionLoading:false,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const sendConnectionEmailRequest = () => ({ type: SEND_CONNECTION_EMIAL_REQUEST });
export const sendConnectionEmailSuccess = () => ({ type: SEND_CONNECTION_EMIAL_SUCCESS });
export const sendConnectionEmailError = e => ({
  type: SEND_CONNECTION_EMIAL_ERROR,
  error: true,
  payload: e,
});

export const saveCompanyRequest = () => ({
  type: SAVE_COMPANY_REQUEST,
});
export const saveCompanySuccess = () => ({
  type: SAVE_COMPANY_SUCCESS,
});
export const saveCompanyError = e => ({
  type: SAVE_COMPANY_ERROR,
  error: true,
  payload: e,
});

export const getConnectionRequest = () => ({
  type: GET_CONNECTION_REQUEST,
});

export const getConnectionSuccess = (isConnected) => ({
  type: GET_CONNECTION_SUCCESS,
  payload:{isConnected}
});

export const getConnectionError = e => ({
  type: GET_CONNECTION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const saveCompany = listingId => (dispatch, getState, sdk) => {
  dispatch(saveCompanyRequest());

  const { currentUser } = getState().user;
  const savedCompanyIds = currentUser?.attributes?.profile?.publicData?.savedCompanyIds;

  return sdk.currentUser
    .updateProfile({
      publicData: {
        savedCompanyIds: savedCompanyIds ? [...savedCompanyIds, listingId] : [listingId],
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(saveCompanySuccess());
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => dispatch(saveCompanyError(storableError(e))));
};

export const showListing = (listingId, config, isOwn = false, isCompanyPage) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const imageAspectHeight = isCompanyPage ? aspectHeight : TALENT_ASPECT_RATIO_HEIGHT;
  const imageAspectWidth = isCompanyPage ? aspectWidth : TALENT_ASPECT_RATIO_WIDTH;
  const aspectRatio = imageAspectHeight / imageAspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(getConnectionFunction(data?.data?.relationships?.author?.data?.id.uuid,listingId));
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const sendInquiry = listing => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.NEW_CONNECTION,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;
      return transactionId;
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

export const sendConnectionEmail = bodyParams => (dispatch, getState, sdk) => {
  dispatch(sendConnectionEmailRequest());

  return sendConnectionEmailAPI(bodyParams)
    .then(response => {
      dispatch(sendConnectionEmailSuccess(response));
      return response;
    })
    .catch(e => dispatch(sendConnectionEmailError(storableError(e))));
};

export const getConnectionFunction = (userId,listingId, config) => (dispatch, getState, sdk) => {
  dispatch(getConnectionRequest());
  const apiQueryParams = {
    userId,
    listingId,
  };
  
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
     
      const isConnected = response?.data?.data?.filter((transaction)=>transaction.attributes.lastTransition !== 'transition/operator-decline').length>0;
      dispatch(getConnectionSuccess(isConnected));
      return response;
    })
    .catch(e => {
      dispatch(getConnectionError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => async dispatch => {
  const listingId = new UUID(params.id);
  const isCompanyPage = ensureCompanySearchOrUserType(params.type);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true, isCompanyPage));
  }

  return dispatch(showListing(listingId, config, false, isCompanyPage));
};
