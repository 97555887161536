import React from 'react';
import PropTypes from 'prop-types';

const Logo = props => {
  const { className, format, ...rest } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="198"
      height="18"
      fill="none"
      viewBox="0 0 198 18"
      {...rest}
    >
      <path
        fill="#B60101"
        d="M8.944.2H.784V17h8.16c6.144 0 10.08-3.24 10.08-8.4S15.088.2 8.944.2zM8.8 15.128h-6V2.072h6c4.776 0 8.16 2.232 8.16 6.528 0 4.296-3.384 6.528-8.16 6.528zM37.538.2v8.76c0 4.128-2.472 6.672-6.84 6.672-4.392 0-6.864-2.544-6.864-6.672V.2h-2.016v8.88c0 4.896 3.096 8.424 8.88 8.424 5.784 0 8.856-3.528 8.856-8.424V.2h-2.016zm24.2 16.8L52.836 6.8 61.21.2h-2.88l-12.72 9.84V.2h-2.016V17h2.016v-4.656l5.592-4.368L59.17 17h2.568zm3.814-1.872V9.392h11.856V7.52H65.552V2.072h13.992V.2H63.536V17h16.248v-1.872H65.552zM104.11.2h-17.88v1.824h7.92V17h2.016V2.024h7.944V.2zM119.515 17h2.28L112.555.2h-2.256l-9.24 16.8h2.232l2.448-4.512h11.328L119.515 17zm-12.792-6.336l4.68-8.616 4.68 8.616h-9.36zm19.068 4.464V.2h-2.016V17h14.88v-1.872h-12.864zm17.011 0V9.392h11.856V7.52h-11.856V2.072h13.992V.2h-16.008V17h16.248v-1.872h-14.232zM175.864.2v13.848L162.16.2h-2.184V17h1.968V2.768L176.056 17h1.776V.2h-1.968zm21.95 0h-17.88v1.824h7.92V17h2.016V2.024h7.944V.2z"
      ></path>
    </svg>
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
