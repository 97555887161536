import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-florida',
    predictionPlace: {
      address: 'Florida',
      bounds: new LatLngBounds(
        new LatLng(31.000968, -79.9323941),
        new LatLng(24.4302905, -87.634896)
      ),
    },
  },
  {
    id: 'default-california',
    predictionPlace: {
      address: 'California',
      bounds: new LatLngBounds(
        new LatLng(42.009503, -114.131211),
        new LatLng(32.534251, -124.5129241)
      ),
    },
  },
  {
    id: 'default-illinois',
    predictionPlace: {
      address: 'Illinois',
      bounds: new LatLngBounds(
        new LatLng(42.5854443, -87.0117187),
        new LatLng(36.970298, -91.513079)
      ),
    },
  },
];

export const defaultLocationsByCity = [
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami',
      bounds: new LatLngBounds(new LatLng(25.979434, -80.144468), new LatLng(25.515125, -80.8736)),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles',
      bounds: new LatLngBounds(
        new LatLng(34.161439, -118.126728),
        new LatLng(33.899991, -118.521447)
      ),
    },
  },
  {
    id: 'default-chicago',
    predictionPlace: {
      address: 'Chicago',
      bounds: new LatLngBounds(
        new LatLng(42.034895347, -87.509753018),
        new LatLng(41.644286, -87.869226)
      ),
    },
  },
];

export default defaultLocations;
