import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { useConfiguration } from '../../../../context/configurationContext';
import * as validators from '../../../../util/validators';

// Import shared components
import { Form, SecondaryButton, FieldCheckboxGroup, FieldTagInput } from '../../../../components';
// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateTalentStyleForm.module.css';
import FieldCheckboxGroupCustom from '../../../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';

const TAB_LISTING_FIELD_IDS = ['program', 'style'];

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentStyleForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="CreateTalentStyleForm.createListingDraftError" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

const CreateTalentStyleFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        onRedirectToPreviousTab,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const config = useConfiguration();
      const listingConfig = config.listing;
      const listingFields = listingConfig.listingFields.filter(l =>
        TAB_LISTING_FIELD_IDS.includes(l.key)
      );

      const program = listingFields.find(l => l.key === 'program');
      const programOptions = program ? program.enumOptions : [];

      const style = listingFields.find(l => l.key === 'style');
      const styleOptions = style ? style.enumOptions : [];

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <div className={css.fieldFlex}>
            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
                <FormattedMessage id="CreateTalentStyleForm.programLabel" />
              </label>
              <p className={css.fieldText}>
                <FormattedMessage id="CreateTalentStyleForm.programText" />
              </p>
              <FieldCheckboxGroupCustom
                className={css.checkboxFields}
                id={`${formId}.${program.key}`}
                name={program.key}
                options={programOptions.map(o => ({ key: o.option, label: o.label }))}
                validate={validators.nonEmptyArray(program.saveConfig.requiredMessage)}
                displayAsRow
              />
            </div>
            <div className={css.fieldContainer}>
              <label className={css.fieldLabel}>
                <FormattedMessage id="CreateTalentStyleForm.styleLabel" />
              </label>
              <p className={css.fieldText}>
                <FormattedMessage id="CreateTalentStyleForm.styleText" />
              </p>
              <FieldCheckboxGroupCustom
                className={css.checkboxFields}
                id={`${formId}.${style.key}`}
                name={style.key}
                options={styleOptions.map(o => ({ key: o.option, label: o.label }))}
                validate={validators.nonEmptyArray(style.saveConfig.requiredMessage)}
                displayAsRow
              />
            </div>
          </div>

          <div className={classNames(css.fieldContainer, css.tagFieldContainer)}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateTalentStyleForm.customStylesLabel" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateTalentStyleForm.customStylesText" />
            </p>
            <FieldTagInput
              className={css.tagField}
              id={`${formId}.customStyles`}
              name="customStyles"
              placeholder={intl.formatMessage({
                id: 'CreateTalentStyleForm.customStylesPlaceholder',
              })}
            />
          </div>

          <PanelFooter onRedirectToPreviousTab={onRedirectToPreviousTab}>
            <SecondaryButton
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </SecondaryButton>
          </PanelFooter>
        </Form>
      );
    }}
  />
);

CreateTalentStyleFormComponent.defaultProps = {
  className: null,
  formId: 'CreateTalentStyleForm',
  fetchErrors: null,
};

CreateTalentStyleFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateTalentStyleFormComponent);
