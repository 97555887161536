import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Promised, AspectRatioWrapper } from '..';

import css from './ImageFromFile.module.css';

// readImage returns a promise which is resolved
// when FileReader has loaded given file as dataURL
const readImage = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      const maxSize = 20 * 1024 * 1024; // 20MB in bytes

      if (file.size > maxSize) {
        console.log('File size exceeds the limit of 20MB.');
        reject(new Error(`${file.name} exceeds the limit of 20 MB`));
      }

      resolve(e.target.result);
    };
    reader.onerror = e => {
      // eslint-disable-next-line
      console.error('Error (', e, `) happened while reading ${file.name}: ${e.target.result}`);
      reject(new Error(`Error reading ${file.name}: ${e.target.result}`));
    };
    reader.readAsDataURL(file);
  });

// Create elements out of given thumbnail file
const ChangeableImageFromFile = props => {
  const { className, rootClassName, aspectWidth, aspectHeight, file, id, children } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <Promised
      key={id}
      promise={readImage(file)}
      renderFulfilled={dataURL => {
        return (
          <div className={classes}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              <img src={dataURL} alt={file.name} className={css.rootForImage} />
            </AspectRatioWrapper>
            {children}
          </div>
        );
      }}
      renderRejected={() => (
        <div className={classes}>
          <FormattedMessage id="ChangeableImageFromFile.couldNotReadFile" />
        </div>
      )}
    />
  );
};

ChangeableImageFromFile.defaultProps = {
  className: null,
  children: null,
  rootClassName: null,
  aspectWidth: 1,
  aspectHeight: 1,
};

const { any, node, string, number } = PropTypes;

ChangeableImageFromFile.propTypes = {
  className: string,
  rootClassName: string,
  aspectWidth: number,
  aspectHeight: number,
  file: any.isRequired,
  id: string.isRequired,
  children: node,
};

export default ChangeableImageFromFile;
