import React, { useEffect } from 'react';
import { useAnimatedInView } from './AboutPage.shared';
import ConfidentialSVG from '../../assets/Confidential.svg';
import FreeSVG from '../../assets/Free.svg';
import VettedSVG from '../../assets/Vetted.svg';
import CheckSVG from '../../assets/mdi_check.svg';
import { useConfiguration } from '../../context/configurationContext';
import SignupForm from '../LandingPage/SignupForm/SignupForm';

import css from './AboutPage.module.css';
import { emailFormatValidWithoutForm } from '../../util/validators';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { generateRandomUUID } from '../../util/uuid';
import {
  saveProfileDataToLocalStorage as saveTalentProfileDataToLocalStorage,
  updateorcreateMailChimpMember,
} from '../CreateTalentPage/CreateTalentPage.duck';
import { saveProfileDataToLocalStorage as saveCompanyProfileDataToLocalStorage } from '../CreateCompanyPage/CreateCompanyPage.duck';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { emailTaken } from '../../ducks/auth.duck';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export const SectionHome = props => {
  const { sectionId, isMobileLayout, errors, isEmailTaken, initialValues } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  const DEBOUNCE_WAIT_TIME = 200;
  const config = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const handleSubmit = values => {
    const { email } = values;

    if (emailFormatValidWithoutForm(email)) {
      dispatch(emailTaken(values));
    }
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const { email } = initialValues || {};
  useEffect(() => {
    if (email && email != undefined) {
      debouncedSubmit({
        email: email,
      });
    }
  }, []);

  return (
    <div id={sectionId}>
      <div className={css.mainContent}>
        <div className={css.leftContent}>
          <div className={css.mainTitle}>
            <FormattedMessage id="LandingPage.SectionHome.mainTitle" />
          </div>
          <div className={css.mobileMap}>
            <div className={css.mainDescription}>
              <FormattedMessage id="LandingPage.SectionHome.mainDescription" />
            </div>
            <div className={css.mainDescription2}>
              {' '}
              <FormattedMessage id="LandingPage.SectionHome.mainDescriptionTwo" />
            </div>
          </div>

          <div className={css.iconDesktopContent}>
            <div className={css.svgContent}>
              <img alt="" src={VettedSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.VettedSVGTitle" />
              </div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={ConfidentialSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.ConfidentialSVGTitle" />
              </div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={FreeSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.FreeSVGTitle" />
              </div>
            </div>
          </div>
        </div>
        <div className={css.formContent}>
          <div className={css.formContentTitle}>
            <FormattedMessage id="LandingPage.SectionHome.formContentTitle" />
          </div>
          {/* <div className={css.formContentDescription}>
          Start connecting with thousands of top designers and studios
        </div> */}
          <SignupForm
            className={css.form}
            saveActionMsg="Submit"
            initialValues={initialValues}
            onSubmit={async values => {
              const { id, firstName, lastName, email, name, registerType } = values;
              const updateValues = {
                id: id ? id : generateRandomUUID(),
                firstName,
                lastName,
                email,
                name,
                registerType,
              };

              if (registerType === 'candidate') {
                const createMailChimpObject = {
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  step: 0,
                  registertype: 'Candidates',
                };
                Promise.all([
                  dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                  dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                ]);

                history.push(
                  createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                );
              } else {
                const createMailChimpObject = {
                  firstName: name,
                  email: email,
                  step: 0,
                  registertype: 'Clients',
                };
                Promise.all([
                  dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                  dispatch(saveCompanyProfileDataToLocalStorage(updateValues)),
                ]);
                history.push(
                  createResourceLocatorString('NewCompanyPage', routes, updateValues, {})
                );
              }
            }}
            marketplaceCurrency={config.currency}
            onEmailChangeWithDebounce={handleChangeWithDebounce}
            isEmailTaken={isEmailTaken}
            fetchErrors={errors ?? {}}
            autoFocus
          />
        </div>
      </div>

      {/* <div className={classNames(css.videoResponsive, css.videoPosition)}>
        <div className={css.videoTextContent}>
          <div className={css.videoTextTitle}>
            <strong>Duke Talent.io</strong>: Connecting Top Designers and Architects with Leading
            Studios
          </div>
          <div className={css.mainDescription}>
            Duke Talent.io redefines the job search process with a vetted and nimble online platform
            that matches leading designers and architects with a curated list of top studios.
          </div>
          <div>
            sdsds
          </div>
        </div>
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/492J8i7R0do?si=0QcE8E67o99g_hrO`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube"
        />
      </div> */}

      {/* <div className={css.videoContent}>
        <div className={css.videoTextContent}>
          <div className={css.videoTextTitle}>
            <FormattedMessage
              id="LandingPage.SectionHome.videotextTitle"
              values={{ boldText: <span style={{ fontWeight: 'bold' }}>Duke Talent.io</span> }}
            />
          </div>
          <div className={css.videoTextDescription}>
            Duke Talent.io redefines the job search process with a vetted and nimble online platform
            that matches leading designers and architects with a curated list of top studios.
          </div>
          <div>
            <div className={css.videoContentWithSVG}>
              <img src={CheckSVG} />
              <div>
                <FormattedMessage
                  id="AboutPage.SectionSolution.textLine2"
                  values={{ boldText: <span style={{ fontWeight: 'bold' }}>transparent</span> }}
                />
              </div>
            </div>
            <div className={css.videoContentWithSVG}>
              <img src={CheckSVG} />
              <div>
                <FormattedMessage
                  id="AboutPage.SectionSolution.textLine3"
                  values={{ boldText: <span style={{ fontWeight: 'bold' }}>curated</span> }}
                />
              </div>
            </div>
            <div className={css.videoContentWithSVG}>
              <img src={CheckSVG} />
              <div>
                Designed to <strong>empower</strong> you, allowing you to take control over your
                careers.
              </div>
            </div>
          </div>
        </div>
        <div className={css.videoYoutubeContent}>
          <iframe
            style={{ width: '100%', height: '100%', border: 'none' }}
            src={`https://www.youtube.com/embed/492J8i7R0do?si=0QcE8E67o99g_hrO`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded YouTube"
          />
        </div>
      </div> */}
    </div>
  );
};

export default SectionHome;
