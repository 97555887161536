import React from 'react';
import { bool, func, object, string, array } from 'prop-types';
import classNames from 'classnames';

// Import util modules
import { FormattedMessage } from '../../../../util/reactIntl';

// Import shared components
import { Heading } from '../../../../components';

// Import modules from this directory
import CreateCompanyPortfolioForm from './CreateCompanyPortfolioForm';
import PanelHeader from '../PanelHeader';
import css from './CreateCompanyPortfolioPanel.module.css';
import { updateorcreateMailChimpMember } from '../../../CreateTalentPage/CreateTalentPage.duck';
import { useDispatch } from 'react-redux';

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateCompanyPortfolioPanel = props => {
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    onManageDisableScrolling,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();
  const { email } = currentProfile || {};

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateCompanyPortfolioPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateCompanyPortfolioForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={currentProfile}
        onSubmit={values => {
          const { profileImage, images,  newsletter,password } = values;

          const updateValues = {
            profileImage,
            images,
            password
          };

          if (newsletter && Array.isArray(newsletter) && newsletter.length > 0) {
            const mailchimpMember = {
              email,
              step: 3,
              registertype:'Clients'
            };

            dispatch(updateorcreateMailChimpMember(mailchimpMember));
          }else{
            // step 4 is stand for unsubsribe
            const mailchimpMember = {
              email,
              step: 4,
              registertype:'Clients'
            };

            dispatch(updateorcreateMailChimpMember(mailchimpMember));
          }

          onSubmit(updateValues);
        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        onManageDisableScrolling={onManageDisableScrolling}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
      />
    </div>
  );
};

CreateCompanyPortfolioPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateCompanyPortfolioPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

export default CreateCompanyPortfolioPanel;
