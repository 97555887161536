import React from 'react';
import { string } from 'prop-types';
import { Field } from 'react-final-form';

import { TagsInput } from 'react-tag-input-component';
import classNames from 'classnames';

import css from './FieldTagInput.module.css';

const FieldTagInputComponent = props => {
  const { input, placeholder } = props;

  const inputValue = input.value || [];

  return (
    <TagsInput
      classNames={{ input: css.tagInputField, tag: css.tagField }}
      value={inputValue}
      onChange={tags => input.onChange(tags)}
      name={input.name}
      placeHolder={placeholder}
    />
  );
};

const FieldTagInput = props => {
  const { rootClassName, className, id, label, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const inputProps = { id, ...rest };
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {label ? (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      ) : null}
      <Field component={FieldTagInputComponent} {...inputProps} />
    </div>
  );
};

FieldTagInput.defualtProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

FieldTagInput.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: string,
};

export default FieldTagInput;
