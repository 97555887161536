import React from 'react';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { ABOUT, HOME, HOW_IT_WORKS } from './AsideNav';

export const OFFSET_MOBILE = 80;
export const OFFSET_DESKTOP = 0;

/**
 * Custom hook to determine the currently active navigation item based on the section in view,
 * accounting for the specified offset.
 * @param {string[]} sectionIds - Array of section IDs.
 * @param {number} [offset=OFFSET_DESKTOP] - The offset value.
 * @returns {string | null} The ID of the currently active navigation item, or null if none is active.
 */
export const useActiveNavItem = (sectionIds, offset = OFFSET_DESKTOP) => {
  const [activeNavItem, setActiveNavItem] = useState(sectionIds[0]); // Initialize with the first section ID

  useEffect(() => {
    /**
     * Event handler for scroll events.
     * Determines the active navigation item based on the section in view.
     */
    const handleScroll = () => {
      const scrollPosition = window.scrollY + offset;
      const threshold = window.innerHeight * 0.2;

      sectionIds.forEach(sectionId => {
        const section = document.getElementById(sectionId);

        if (!section) return;

        const sectionOffset = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionOffset - threshold &&
          scrollPosition < sectionOffset + sectionHeight - threshold
        ) {
          setActiveNavItem(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionIds, offset]);

  return activeNavItem;
};

/**
 * Scrolls to the center of the section with the specified id, accounting for the specified offset.
 * @param {string} id - The id of the section to scroll to.
 * @param {number} offset - The offset value.
 * @param {boolean} isMobileLayout - Indicates whether the layout is mobile or desktop.
 * @returns {void}
 */
export const scrollToSection = (id, offset = 0, isMobileLayout) => {
  if (id === ABOUT || id===HOME) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return;
  }

  const element = document.getElementById(id);
  if (element) {
    if (isMobileLayout) {
      // Scroll normally
      const topPos = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
    } else {
      // Scroll to the center of the section
      const viewportHeight = window.innerHeight;
      const elementHeight = element.getBoundingClientRect().height;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const topPos = elementTop - (viewportHeight - elementHeight) / 2 - offset ;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
    }
  }
};

/**
 * Generates responsive settings for the carousel based on different device breakpoints.
 * @returns {object} Responsive settings for the carousel.
 */
export const getCarouselResponsiveSettings = () => ({
  responsive: {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 24,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 24,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 24,
    },
  },
});

/**
 * Calculates the content width based on the offsetWidth of a specific section element.
 * @param {string} sectionId - The id of the section element to calculate the content width for.
 * @returns {number} The content width.
 */
export const getContentWidth = (sectionId = HOW_IT_WORKS) => {
  const sectionEl = typeof document !== 'undefined' ? document.getElementById(sectionId) : null;

  // Calculate content width by subtracting the difference between window width and document body width from the section offsetWidth
  return sectionEl ? sectionEl.offsetWidth - (window.innerWidth - document.body.clientWidth) : 0;
};

/**
 * Custom hook to animate elements when they come into view using Framer Motion.
 * @param {Object} options - Options for the useInView hook.
 * @returns {Object} An object containing a ref, inView state, and AnimatedDiv component.
 */
export const useAnimatedInView = (options = {}) => {
  const { animate, ...rest } = options;
  const [ref, inView] = useInView(rest);

  const AnimatedDiv = ({ children, delay }) =>
    animate ? (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -20 }}
        transition={{ duration: 0.5, delay }}
      >
        {children}
      </motion.div>
    ) : (
      children
    );

  return {
    ref,
    inView,
    AnimatedDiv,
  };
};

// Array containing reviews for the About page
export const reviews = [
  {
    id: '396ce14c-6aa7-4a4d-b8f4-af265996fc66',
    author: 'Interior Designer in West Hollywood',
    content:
      'I love Duke Talent’s platform. It’s like a road map to the best design firms in the country.',
  },
  {
    id: 'ca17b02b-e4c4-4b39-88ca-249cf890c0e7',
    author: 'Hiring Manager at NY/LA-based AD100 firm',
    content:
      'I had the pleasure of working with Duke for several placements at an AD100 firm. Hands down, his candidates were the most impressive and talented individuals we had come across in our various searches. Duke’s sensibility of knowing the market and what a company needs, is very helpful and feels unique from other recruiting firms. It’s a true collaboration working with Duke Talent.',
  },
  {
    id: '61e98c7b-99fa-4636-8e29-e5128e38c295',
    author: 'Interior Architect in Manhattan',
    content:
      'The Duke Talent Platform does a great job keeping the client list tight. Since I could filter by style and size, I was able to find my dream firm.',
  },
  {
    id: '08784714-d3c1-45d6-9348-1b0821aeb75b',
    author: 'Hiring Manager at NY-based AD100 firm',
    content:
      'I have been working with Duke for many years for all of our staffing needs. We love working with him, he clearly understands our needs and is always on point with top-notch candidates and service. I highly recommend Duke Talent, as it’s been a pleasure working alongside him all these years. You will not be disappointed!',
  },
];

// Array containing faqs for the About page
export const faqs = [
  {
    id: '3af392d3-7446-4e6c-a9fb-10fb4627f24b',
    question: 'Who does Duke Talent work with?',
    answer:
      'We partner with the design industry’s elite studios, including over 25 AD100 firms.',
  },
  {
    id: '576f13c5-4914-46a6-9c73-873a45c6e856',
    question:
      'Is this platform exclusively for Designers/Architects actively seeking job opportunities?',
    answer:
      'Absolutely not. Our platform serves as a curated and vetted community of top-tier talent, offering a place for members to connect, learn, and cultivate professional growth.',
  },
  {
    id: 'd09e0b4d-a180-42c6-b06a-bfc6ea4e7d45',
    question: 'Is this platform just for Designers and Architects?',
    answer:
      'Yes, Duke Talent caters exclusively to this niche - your niche. We specialize in serving Designers and Architects focusing on high-end projects, whether you\'re shaping homes, hotels, restaurants, retail spaces, or workspaces.',
  },
  {
    id: '30815924-028b-4b3f-bdbb-a0222d09d769',
    question: 'How do I know all the clients and profiles are real?',
    answer:
      'Every user undergoes a rigorous membership application process before being granted access to the platform. This comprehensive vetting procedure entails interviews and identity verification to ensure authenticity before acceptance..',
  },
  {
    id: '7f10ee48-856f-40b1-996a-eaedab5507ac',
    question: 'What if I am solely interested in pursuing consulting opportunities?',
    answer:
      'DukeTalent.io is perfect for that. Many top firms actively use our platform to source project-based consultants, making it an excellent resource for securing part-time or freelance consulting roles that match your expertise.',
  },
  {
    id: 'ee9c1c04-2fea-4d98-9a63-9713a8972533',
    question: 'What about privacy?',
    answer:
      'The platform operates with utmost discretion and confidentiality, maintaining a private space for like-minded designers to connect and grow. It is not publicly accessible. Our clients and candidates are keenly aware of the importance of confidentiality within this close-knit, exclusive, and specialized industry.',
  },
  {
    id: 'ee9c1c04-2fea-4d98-9a63-9713a897wsqh',
    question: 'Can I hide my profile from specific companies?',
    answer:
      'Yes, you can choose which companies to hide your profile from.',
  },
];
