import React from 'react';
import { string, node, func } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { SecondaryButton } from '../../../components';
import classNames from 'classnames';

import css from './PanelFooter.module.css';

const PanelFooter = props => {
  const { rootClassName, className, children, onRedirectToPreviousTab } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <SecondaryButton className={css.backButton} type="button" onClick={onRedirectToPreviousTab}>
        <FormattedMessage id="PanelFooter.backButton" />
      </SecondaryButton>
      {children}
    </div>
  );
};

PanelFooter.defaultProps = {
  rootClassName: null,
  className: null,
};

PanelFooter.propTypes = {
  rootClassName: string,
  className: string,
  children: node.isRequired,
  onRedirectToPreviousTab: func.isRequired,
};

export default PanelFooter;
