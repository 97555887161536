import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMap.module.css';

const IconMap = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <g clipPath="url(#clip0_1591_3976)">
        <path
          fill="#B60101"
          d="M18.428 3.005L12.473.536 6.546 2.882.572.537a.528.528 0 00-.148-.02C.18.515 0 .697 0 .97v15.4c0 .327.257.666.572.753l5.98 2.36 5.92-2.403 5.956 2.34c.051.014.101.021.148.021.244 0 .424-.18.424-.454V3.76a.831.831 0 00-.572-.755zm-11.303.856L11.875 2v14.13l-4.75 1.877V3.86zM1.187 2.108l4.75 1.756v14.134l-4.75-1.935V2.108zm16.625 15.797l-4.75-1.789V2.018l4.75 2.049v13.838z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1591_3976">
          <path fill="#fff" d="M0 0H19V19H0z" transform="translate(0 .5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconMap.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMap.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMap;
