import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FieldTagInput } from '../../components';
import FieldCheckboxGroupCustom from '../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';
import * as validators from '../../util/validators';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';


const TalentSignUpStyleForm = props => {
  const {
    sectionId,
    formId,
    intl,
    program,
    programOptions,
    style,
    styleOptions,
    values,
  } = props;
  return (
    <TalentFormContent id={sectionId} title={intl.formatMessage({
      id: 'TalentSignUpPage.TalentSignUpStyleForm.title',
    })}>
      <div className={css.fieldsWrapper}>
        <div className={css.customfieldFlex}>
          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateTalentStyleForm.programLabel" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateTalentStyleForm.programText" />
            </p>
            <FieldCheckboxGroupCustom
              className={css.checkboxFields}
              id={`${formId}.${program.key}`}
              name={program.key}
              options={programOptions.map(o => ({ key: o.option, label: o.label }))}
              validate={validators.nonEmptyArray(program.saveConfig.requiredMessage)}
              displayAsRow
            />
          </div>
          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>
              <FormattedMessage id="CreateTalentStyleForm.styleLabel" />
            </label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateTalentStyleForm.styleText" />
            </p>
            <FieldCheckboxGroupCustom
              className={css.checkboxFields}
              id={`${formId}.${style.key}`}
              name={style.key}
              options={styleOptions.map(o => ({ key: o.option, label: o.label }))}
              validate={validators.nonEmptyArray(style.saveConfig.requiredMessage)}
              displayAsRow
            />
          </div>
        </div>

        <div className={classNames(css.fieldContainer, css.tagFieldContainer)}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateTalentStyleForm.customStylesLabel" />
          </label>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateTalentStyleForm.customStylesText" />
          </p>
          <FieldTagInput
            className={css.tagField}
            id={`${formId}.customStyles`}
            name="customStyles"
            placeholder={intl.formatMessage({
              id: 'CreateTalentStyleForm.customStylesPlaceholder',
            })}
          />
        </div>
      </div>
    </TalentFormContent>);
};

export default TalentSignUpStyleForm;
