import React from 'react';

import classNames from 'classnames';

// Import util modules
import { FormattedMessage } from '../../util/reactIntl';
import { nonEmptyArray, required } from '../../util/validators';

// Import shared components
import {
  FieldCheckboxGroup,
  FieldRadioButtonGroup,
  FieldSelect,
  FieldTextInput,
} from '../../components';
// Import modules from this directory
import FieldCheckboxGroupCustom from '../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';
import css from './CompanySignUpPage.module.css';
import { CompanyFormContent } from './CompanySignUpPage';

const INDUSTRY_BOTH_COMBINED_KEY = 'architecture,interior-design,both';
const INDUSTRY_BOTH_KEY = 'both';

const CompanytSignUpIndustryForm = props => {
  const {
    sectionId,
    formId,
    intl,
    industry,
    industryOptions,
    primarySpecialty,
    primarySpecialtyOptions,
    secondarySpecialty,
    secondarySpecialtyOptions,
    style,
    styleOptions,
    wfhPolicy,
    wfhPolicyOptions,
    openTo,
    openToOptions,
    ad100,
    ad100Options,
    values,
  } = props;
  return (
    <CompanyFormContent
      id={sectionId}
      title={intl.formatMessage({
        id: 'CompanytSignUpPage.CompanytSignUpIndustryForm.title',
      })}
    >
      <div className={css.fieldsWrapper}>
        <FieldRadioButtonGroup
          className={classNames(css.field, css.radioFields)}
          id={`${formId}.${industry.key}`}
          name={industry.key}
          label={industry.saveConfig.label}
          options={industryOptions.map(o => ({
            key: o.option === INDUSTRY_BOTH_COMBINED_KEY ? INDUSTRY_BOTH_KEY : o.option,
            label: o.label,
          }))}
        />

        <div className={css.fieldsFlex}>
          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>{primarySpecialty.saveConfig.label}</label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateCompanyIndustryForm.specialtyText" />
            </p>

            <FieldSelect
              id={`${formId}.primarySpecialty`}
              name="primarySpecialty"
              validate={required(primarySpecialty.saveConfig.requiredMessage)}
              required
            >
              <option value="" disabled>
                {primarySpecialty.saveConfig.placeholderMessage}
              </option>
              {primarySpecialtyOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
          <div className={css.field}>
            <label className={css.fieldLabel}>{secondarySpecialty.saveConfig.label}</label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateCompanyIndustryForm.secondarySpecialtyText',
              })}
            </p>
            <FieldCheckboxGroup
              className={css.checkboxFields}
              id={`${formId}.${secondarySpecialty.key}`}
              name={secondarySpecialty.key}
              options={secondarySpecialtyOptions.map(o => ({ key: o.option, label: o.label }))}
              twoColumns
            />
          </div>
        </div>

        <div style={{ marginBottom: '48px' }}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateCompanyIndustryForm.style" />
          </label>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateCompanyIndustryForm.styleText" />
          </p>
          <FieldCheckboxGroupCustom
            className={classNames(css.checkboxFieldsCustom, css.stylesCheckboxFields)}
            id={`${formId}.${style.key}`}
            name={style.key}
            options={styleOptions.map(o => ({ key: o.option, label: o.label }))}
            validate={nonEmptyArray(style.saveConfig.requiredMessage)}
            displayAsRow
          />
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>{ad100.saveConfig.label}</label>
          <p className={css.fieldText}>
            {intl.formatMessage({
              id: 'CreateCompanyIndustryForm.ad100Text',
            })}
          </p>
          <FieldCheckboxGroup
            className={classNames(css.checkboxFields, css.oneColumn)}
            id={`${formId}.${ad100.key}`}
            name={ad100.key}
            options={ad100Options.map(o => ({ key: o.option, label: o.label }))}
          />
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>{wfhPolicy.saveConfig.label}</label>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateCompanyIndustryForm.wfhPolicyText" />
          </p>
          <FieldSelect
            id={`${formId}.wfhPolicy`}
            name="wfhPolicy"
            validate={required(wfhPolicy.saveConfig.requiredMessage)}
            required
          >
            <option value="" disabled>
              {wfhPolicy.saveConfig.placeholderMessage}
            </option>
            {wfhPolicyOptions.map(o => {
              return (
                <option key={o.option} value={o.option}>
                  {o.label}
                </option>
              );
            })}
          </FieldSelect>
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateCompanyIndustryForm.openToLabel" />
          </label>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateCompanyIndustryForm.openToText" />
          </p>
          <FieldSelect
            id={`${formId}.openTo`}
            name="openTo"
            validate={required(openTo.saveConfig.requiredMessage)}
            required
          >
            <option disabled value="">
              {openTo.saveConfig.placeholderMessage}
            </option>
            {openToOptions.map(o => {
              return (
                <option key={o.option} value={o.option}>
                  {o.label + ' candidates'}
                </option>
              );
            })}
          </FieldSelect>
        </div>

        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            <FormattedMessage id="CreateCompanyIndustryForm.description" />
          </label>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateCompanyIndustryForm.descriptionText" />
          </p>
          <FieldTextInput
            className={css.description}
            id={`${formId}.description`}
            name="description"
            type="textarea"
            placeholder={intl.formatMessage({
              id: 'CreateCompanyIndustryForm.descriptionPlaceholder',
            })}
            validate={required(
              intl.formatMessage({
                id: 'CreateCompanyIndustryForm.descriptionRequired',
              })
            )}
          />
        </div>
      </div>
    </CompanyFormContent>
  );
};

export default CompanytSignUpIndustryForm;
