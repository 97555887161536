import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="15"
      fill="none"
      viewBox="0 0 24 15"
    >
      <path stroke="#B60101" d="M0 0.5L24 0.5"></path>
      <path stroke="#B60101" d="M0 7.5L24 7.5"></path>
      <path stroke="#B60101" d="M0 14.5L24 14.5"></path>
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
