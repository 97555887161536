/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  NEW_CONNECTION: 'transition/new-connection',
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

export const states = {
  INITIAL: 'initial',
  CONNECTION: 'connection',
  CONNECTION_ACCEPTED: 'connection-accepted',
  CONNECTION_DECLINED: 'connection-declined',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-connection/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.NEW_CONNECTION]: states.CONNECTION,
      },
    },
    [states.CONNECTION]: {
      on: {
        [transitions.OPERATOR_ACCEPT]: states.CONNECTION_ACCEPTED,
        [transitions.OPERATOR_DECLINE]: states.CONNECTION_DECLINED,
      },
    },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.NEW_CONNECTION,
    transitions.OPERATOR_ACCEPT,
    transitions.OPERATOR_DECLINE,
  ].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [transitions.OPERATOR_ACCEPT, transitions.OPERATOR_DECLINE];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [transitions.NEW_CONNECTION];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.CONNECTION];
