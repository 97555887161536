import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect } from 'react';

// Import util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { generateRandomUUID } from '../../../../util/uuid';
import { emailFormatValidWithoutForm } from '../../../../util/validators';

// Import shared components
import { Heading } from '../../../../components';

// Import modules from this directory
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { injectIntl } from '../../../../util/reactIntl';
import { updateorcreateMailChimpMember } from '../../CreateTalentPage.duck';
import PanelHeader from '../PanelHeader';
import CreateTalentBasicsForm from './CreateTalentBasicsForm';
import css from './CreateTalentBasicsPanel.module.css';

// When user types, we wait for new keystrokes a while before searching new content
const DEBOUNCE_WAIT_TIME = 600;

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateTalentBasicsPanelComponent = props => {
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    onEmailTaken,
    isEmailTaken,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = values => {
    const { email } = values;

    if (emailFormatValidWithoutForm(email)) {
      onEmailTaken(values);
    }
  };
  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });
  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const { email } = currentProfile || {};
  useEffect(() => {
    if (email && email!=undefined) {
      debouncedSubmit({
        email:email
      });
    }
  }, []);

  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateTalentBasicsPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateTalentBasicsForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={currentProfile}
        onSubmit={values => {
          const { id, firstName, lastName, email, password, phone, birthday } = values;

          const updateValues = {
            id: id ? id : generateRandomUUID(),
            firstName,
            lastName,
            email,
            // password,
            phone,
            birthday,
          };
          dispatch(
            updateorcreateMailChimpMember({ ...values, step: 1, registertype: 'Candidates' })
          );
          onSubmit(updateValues);
        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        onEmailChangeWithDebounce={handleChangeWithDebounce}
        isEmailTaken={isEmailTaken}
        autoFocus
      />
    </div>
  );
};

CreateTalentBasicsPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateTalentBasicsPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

const mapDispatchToProps = dispatch => ({
  updateorcreateMailChimpMember: values => dispatch(updateorcreateMailChimpMember(values)),
});

const CreateTalentBasicsPanel = compose(
  withRouter,
  connect(mapDispatchToProps),
  injectIntl
)(CreateTalentBasicsPanelComponent);

export default CreateTalentBasicsPanel;
