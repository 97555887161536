import React from 'react';

import { OnChange } from 'react-final-form-listeners';
import { FieldBirthdayInput, FieldPhoneNumberInput, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';

const TalentSignUpBasicForm = props => {
  const {
    sectionId,
    formId,
    firstNameRequired,
    lastNameRequired,
    intl,
    onEmailChangeWithDebounce,
    emailRequired,
    emailValid,
    values,
  } = props;
  return (
    <TalentFormContent id={sectionId} title={intl.formatMessage({
      id: 'TalentSignUpPage.TalentSignUpBasicForm.title',
    })}>
      <div className={css.fieldsWrapper}>
        <label>Personal Info</label>
        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.firstName`}
            name="firstName"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.firstNamePlaceholder',
            })}
            validate={firstNameRequired}
          />
          <FieldTextInput
            id={`${formId}.lastName`}
            name="lastName"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.lastNamePlaceholder',
            })}
            validate={lastNameRequired}
          />
        </div>

        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.email`}
            name="email"
            className={css.field}
            type="email"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <OnChange name="email">
            {(value, previous) => onEmailChangeWithDebounce({ email: value })}
          </OnChange>
          <FieldPhoneNumberInput
            id={`${formId}.phone`}
            name="phone"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.phonePlaceholder',
            })}
          />
        </div>

        <div className={css.field}>
          <label>Date of Birth</label>
          <FieldBirthdayInput
            id={`${formId}.birthday`}
            name="birthday"
            valueFromForm={values.birthday}
          />
        </div>
      </div>
    </TalentFormContent>
  );
};

export default TalentSignUpBasicForm;
