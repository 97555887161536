import React, { useState } from 'react';
import { string, array, bool, func } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { FormattedMessage } from '../../util/reactIntl';

// Import shared components
import { Modal, Heading, InlineTextButton, SecondaryButton, IconEdit } from '../../components';
import IconPlus from '../../containers/SearchPage/IconPlus/IconPlus';
import AddProjectForm, {
  FormFields as EditFormFields,
} from '../../containers/CreateCompanyPage/CreateCompanyWizard/CreateCompanyPortfolioPanel/AddProjectForm';

// Import modules from this directory
import css from './FieldProjects.module.css';

const MAX_TEXT_LENGTH = 18; // maximum row text length

const shortenText = text =>
  text.length > MAX_TEXT_LENGTH ? text.substring(0, MAX_TEXT_LENGTH) + '...' : text;

/**
 * A component that renders single project.
 */
const SingleProject = props => {
  const { intl, name, project, onDeleteProject, onManageDisableScrolling } = props;
  const { projectName, completedAt, completedDate } = project;

  const [isModalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState(project.images || []);

  return (
    <>
      <li className={css.projectListItem}>
        <div className={classNames(css.projectListItemRow, css.projectListItemFirstRow)}>
          <span className={css.projectListItemRowHeading}>{shortenText(projectName)}</span>
          <span
            className={classNames(
              css.projectListItemRowSubHeading,
              css.projectListItemRowSubHeadingMobile
            )}
          >
            {completedDate}
          </span>
        </div>
        <div className={css.projectListItemRow}>
          <span className={css.projectListItemRowSubHeading}>
            <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.completedAt" />
          </span>
          <span className={css.projectListItemRowHeading}>{shortenText(completedAt)}</span>
        </div>
        <div className={css.projectListItemRow}>
          <span className={css.projectListItemRowSubHeading}>{completedDate}</span>
        </div>
        <div className={classNames(css.projectListItemRow, css.projectListItemLastRow)}>
          <span className={css.projectListItemRowSmallText}>
            <FormattedMessage
              id="CreateCompanyPortfolioForm.FieldProjects.imagesUploaded"
              values={{ count: files.length }}
            />
          </span>
          {files.length > 0 && (
            <div className={css.projectListItemImages}>
              {files.slice(0, 3).map(f => {
                return (
                  <img
                    key={f.preview}
                    src={f.preview}
                    alt={f.name}
                    className={css.projectListItemImage}
                  />
                );
              })}
            </div>
          )}
          <InlineTextButton
            type="button"
            className={css.editIconWrapper}
            onClick={() => setModalOpen(true)}
          >
            <IconEdit className={css.editIcon} isOpen={false} />
          </InlineTextButton>
        </div>
      </li>
      <Modal
        id={`FieldProjects.editProjectModal.${name}`}
        closeButtonMessage={intl.formatMessage({
          id: 'CreateCompanyPortfolioForm.FieldProjects.editProjectModalCloseButton',
        })}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <Heading className={css.editProjectHeading} as="h2">
          <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.editProject" />
        </Heading>
        <p className={css.editProjectText}>
          <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.editProjectText" />
        </p>
        <EditFormFields
          formId="EditProjectForm"
          intl={intl}
          name={name}
          files={files}
          setFiles={setFiles}
        />
        <SecondaryButton
          type="button"
          className={css.editProjectModalButton}
          onClick={onDeleteProject}
        >
          <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.deleteProject" />
        </SecondaryButton>
      </Modal>
    </>
  );
};

/**
 * A component that renders list of projects.
 */
const FieldProjects = props => {
  const {
    rootClassName,
    className,
    intl,
    form,
    name,
    projects,
    showAddProjectModal,
    setShowAddProjectModal,
    onManageDisableScrolling,
  } = props;
  const [files, setFiles] = useState([]);
  const classes = classNames(rootClassName || css.root, className);

  const projectsCount = projects && projects?.length;
  const hasProjects = projectsCount > 0;

  const { push } = form.mutators;

  const handleAddProject = (values, currentForm = null) => {
    push(name, values);
    setShowAddProjectModal(false);
    currentForm?.restart();
    setFiles([]);
  };

  const addProjectButton = (
    <div
      className={classNames(css.addProjectWrapper, {
        [css.addProjectsListWrapper]: hasProjects,
      })}
    >
      <InlineTextButton
        className={css.addProjectButton}
        type="button"
        onClick={() => setShowAddProjectModal(true)}
      >
        <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.addProject" />
        <span className={css.plusIconWrapper}>
          <IconPlus className={css.plusIcon} isOpen={false} />
        </span>
      </InlineTextButton>
    </div>
  );

  return (
    <div className={classes}>
      <FieldArray name={name}>
        {({ fields }) => {
          return (
            <ul className={css.projectList}>
              {fields.map((fieldName, index) => {
                const currentField = projects[index];
                return (
                  <SingleProject
                    key={fieldName}
                    intl={intl}
                    index={index}
                    name={fieldName}
                    project={currentField}
                    onDeleteProject={() => fields.remove(index)}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                );
              })}
            </ul>
          );
        }}
      </FieldArray>
      {addProjectButton}
      <Modal
        id="FieldProjects.addNewProject"
        isOpen={showAddProjectModal}
        onClose={() => setShowAddProjectModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <Heading className={css.addNewProjectHeading} as="h2">
          <FormattedMessage id="CreateCompanyPortfolioForm.FieldProjects.addNewProject" />
        </Heading>
        <AddProjectForm onSubmit={handleAddProject} files={files} setFiles={setFiles} />
      </Modal>
    </div>
  );
};

FieldProjects.defualtProps = {
  rootClassName: null,
  className: null,
  name: null,
  projects: [],
  showAddProjectModal: false,
  setShowAddProjectModal: null,
  onManageDisableScrolling: null,
};

FieldProjects.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  projects: array,
  showAddProjectModal: bool.isRequired,
  setShowAddProjectModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default FieldProjects;
