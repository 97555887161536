import React from 'react';
import { string, number } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import css from './PanelHeader.module.css';

const PanelHeader = props => {
  const { rootClassName, className, tabLabel, order, length } = props;
  const classes = classNames(rootClassName || css.root, className);

  const fillerWidth = 100 / (length + 1);
  const fillerWidthNum = fillerWidth * order;

  return (
    <div className={classes}>
      <div className={css.filler}>
        <div className={css.fillerBar} style={{ width: `${fillerWidthNum}%` }} />
      </div>
      <div className={css.content}>
        <span className={css.heading}>{tabLabel}</span>
        <span className={css.order}>
          <FormattedMessage id="PanelHeader.order" values={{ order, length }} />
        </span>
      </div>
    </div>
  );
};

PanelHeader.defaultProps = {
  rootClassName: null,
  className: null,

  tabLabel: null,
  order: 0,
  length: 0,
};

PanelHeader.propTypes = {
  rootClassName: string,
  className: string,

  tabLabel: string.isRequired,
  order: number.isRequired,
  length: number.isRequired,
};

export default PanelHeader;
