import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="-0.355 -0.355 17 17"
    >
      <path
        strokeLinejoin="round"
        strokeWidth="1"
        d="M12.218 4.412a.34.34 0 0 1 0-.679M12.218 4.412a.34.34 0 0 0 0-.679M4.751 8.145a3.394 3.394 0 1 0 6.788 0 3.394 3.394 0 1 0-6.788 0"
      />
      <path
        strokeWidth="1"
        d="M11.2 1.018H5.09a4.073 4.073 0 0 0-4.072 4.073v6.108a4.073 4.073 0 0 0 4.073 4.073h6.108a4.073 4.073 0 0 0 4.073-4.073V5.091a4.073 4.073 0 0 0-4.073-4.073Z"
      />
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
