import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconChevron.module.css';

const IconChevron = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.35355 3.35355C3.1583 3.5488 2.8417 3.5488 2.64645 3.35355L0.146445 0.853555C-0.048815 0.65829 -0.048815 0.34171 0.146445 0.146445C0.34171 -0.048815 0.65829 -0.048815 0.853555 0.146445L3 2.2929L5.14645 0.146445C5.3417 -0.048815 5.6583 -0.048815 5.85355 0.146445C6.0488 0.34171 6.0488 0.65829 5.85355 0.853555L3.35355 3.35355Z"
        fill="#B60101"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconChevron.defaultProps = {
  className: null,
  rootClassName: null,
};

IconChevron.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChevron;
