import React from 'react';

import { FieldCheckboxGroup, FieldRadioButtonGroup, FieldSelect } from '../../components';
import * as validators from '../../util/validators';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';
import { FormattedMessage } from 'react-intl';
import { LocationSearchField } from '../SearchPage/LocationFilter/LocationFilter';
import classNames from 'classnames';

const ARCHITECTURE_KEY = 'architecture';
const INDUSTRY_BOTH_COMBINED_KEY = 'architecture,interior-design,both';
const INDUSTRY_BOTH_KEY = 'both';
const MAPBOX_MAP_CITY_TYPES = ['place'];

const TalentSignUpWorkForm = props => {
  const {
    sectionId,
    formId,
    intl,
    industry,
    industryOptions,
    isMobileLayout,
    primarySpecialty,
    primarySpecialtyOptions,
    secondarySpecialty,
    secondarySpecialtyOptions,
    expertise,
    expertiseOptions,
    values,
  } = props;
  return (
    <TalentFormContent id={sectionId} title={intl.formatMessage({
      id: 'TalentSignUpPage.TalentSignUpWorkForm.title',
    })}>
      <div className={css.fieldsWrapper}>
        <div className={css.fieldContainer}>
          <label className={css.fieldLabel}>
            {intl.formatMessage({
              id: 'CreateTalentWorkForm.locationLabel',
            })}
          </label>
          <p className={css.fieldText}>
            {intl.formatMessage({
              id: 'CreateTalentWorkForm.locationText',
            })}
          </p>

          <LocationSearchField
            intl={intl}
            placeholder={intl.formatMessage({
              id: 'CreateCompanyBasicsForm.addressPlaceholder',
            })}
            isMobile={isMobileLayout}
            types={MAPBOX_MAP_CITY_TYPES}
            hideDefaultPredictions
            onLocationChange={() => { }}
          />
        </div>

        <FieldRadioButtonGroup
          className={classNames(css.field, css.radioFields)}
          id={`${formId}.${industry.key}`}
          name={industry.key}
          label={industry.saveConfig.label}
          options={industryOptions.map(o => ({
            key: o.option === INDUSTRY_BOTH_COMBINED_KEY ? INDUSTRY_BOTH_KEY : o.option,
            label: o.label,
            text:
              o.option === ARCHITECTURE_KEY
                ? intl.formatMessage({
                  id: 'CreateTalentWorkForm.architectureLabelMessage',
                })
                : null,
          }))}
        />

        <div className={css.fieldsFlex}>
          <div className={css.fieldContainer}>
            <label className={css.fieldLabel}>{primarySpecialty.saveConfig.label}</label>
            <p className={css.fieldText}>
              <FormattedMessage id="CreateTalentWorkForm.specialtyText" />
            </p>

            <FieldSelect
              id={`${formId}.primarySpecialty`}
              name="primarySpecialty"
              validate={validators.required(primarySpecialty.saveConfig.requiredMessage)}
              required
            >
              <option disabled value="">
                {primarySpecialty.saveConfig.placeholderMessage}
              </option>
              {primarySpecialtyOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
          <div className={css.field}>
            <label className={css.fieldLabel}>{secondarySpecialty.saveConfig.label}</label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateTalentWorkForm.secondarySpecialtyText',
              })}
            </p>
            <FieldCheckboxGroup
              className={classNames(css.checkboxFields, css.twoColumns)}
              id={`${formId}.${secondarySpecialty.key}`}
              name={secondarySpecialty.key}
              options={secondarySpecialtyOptions.map(o => ({
                key: o.option,
                label: o.label,
              }))}
              threeColumns={true}
            />
          </div>
        </div>

        <div className={css.fieldsFlex}>
          <div className={css.field}>
            <label className={css.fieldLabel}>{expertise.saveConfig.label}</label>
            <p className={css.fieldText}>
              {intl.formatMessage({
                id: 'CreateTalentWorkForm.secondarySpecialtyText',
              })}
            </p>
            <FieldCheckboxGroup
              className={classNames(css.checkboxFields, css.twoColumns)}
              id={`${formId}.${expertise.key}`}
              name={expertise.key}
              options={expertiseOptions.map(o => ({ key: o.option, label: o.label }))}
              threeColumns={true}
            />
          </div>
        </div>
      </div>
    </TalentFormContent>
  );
};

export default TalentSignUpWorkForm;
