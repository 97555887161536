import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInquiry.module.css';

const IconInquiry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="-1 -1 32 32"
    >
      <g className={css.marketplaceColorStroke} strokeWidth="2">
        <path d="M2.5 5h25v20h-25z" />
        <path d="m2.5 8.75 12.5 7.5 12.5-7.5" />
      </g>
    </svg>
  );
};

IconInquiry.defaultProps = {
  rootClassName: null,
  className: null,
};

IconInquiry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconInquiry;
