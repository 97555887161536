import classNames from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldCheckboxGroup, FieldSelect, FieldTextInput, SecondaryButton } from '../../components';
import * as validators from '../../util/validators';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';

const ACCEPT_IMAGES = 'image/gif, image/jpeg, image/png';

const TalentSignUpPortfolioForm = props => {
  const {
    sectionId,
    formId,
    intl,
    availability,
    availabilityOptions,
    openTo,
    openToOptions,
    workplacePreference,
    workplacePreferenceOptions,
    form,
    setUploadedFile,
    profileImageRequired,
    chooseLogoLabel,
    passwordValidators,
    confirmPasswordValidators,
    submitInProgress,
    submitDisabled,
    submitReady,
    saveActionMsg,
    values,
  } = props;
  const publishProfileMessage = intl.formatMessage({
    id: 'CreateTalentPortfolioForm.publishProfile',
  });
  return (
    <TalentFormContent id={sectionId} title={intl.formatMessage({
      id: 'TalentSignUpPage.TalentSignUpPortfolioForm.title',
    })}>
      <div className={css.fieldsWrapper}>
        <div className={css.fieldContainer}>
          <span className={classNames(css.fieldLabel, css.fieldLabelWithMargin)}>
            <FormattedMessage id="CreateTalentPortfolioForm.availabilityLabel" />
          </span>
          <div className={css.fieldFlex}>
            <FieldSelect
              id={`${formId}.availability`}
              name="availability"
              className={classNames(css.field, css.selectField)}
              validate={validators.required(availability.saveConfig.requiredMessage)}
              required
            >
              <option value="" disabled>
                {availability.saveConfig.placeholderMessage}
              </option>
              {availabilityOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label}
                  </option>
                );
              })}
            </FieldSelect>
            <FieldSelect
              id={`${formId}.openTo`}
              name="openTo"
              className={classNames(css.field, css.selectField)}
              validate={validators.required(openTo.saveConfig.requiredMessage)}
              required
            >
              <option value="" disabled>
                {openTo.saveConfig.placeholderMessage}
              </option>
              {openToOptions.map(o => {
                return (
                  <option key={o.option} value={o.option}>
                    {o.label.toLowerCase() === 'both' ? 'Either/or' : o.label + ' position'}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
        </div>

        <div className={css.fieldContainer}>
          <span className={classNames(css.fieldLabel, css.fieldLabelWithMargin)}>
            <FormattedMessage id="CreateTalentPortfolioForm.workplacePreferenceLabel" />
          </span>
          <FieldCheckboxGroup
            id={`${formId}.workplacePreference`}
            name="workplacePreference"
            className={classNames(css.field, css.selectField)}
            options={workplacePreferenceOptions.map(o => ({
              key: o.option,
              label: o.label,
            }))}
            validate={validators.nonEmptyArray(workplacePreference.saveConfig.requiredMessage)}
          />
        </div>

        <div className={css.fieldContainer}>
          <span className={css.fieldLabel}>
            <FormattedMessage id="CreateTalentPortfolioForm.linksLabel" />
          </span>
          <div className={css.fieldLinkFlex}>
            <FieldTextInput
              id={`${formId}.website`}
              name="website"
              className={classNames(css.field, css.customField)}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentPortfolioForm.websitePlaceholder',
              })}
              validate={validators.validURL(
                intl.formatMessage({
                  id: 'CreateTalentPortfolioForm.websiteValid',
                })
              )}
            />
            <FieldTextInput
              id={`${formId}.linkedin`}
              name="linkedin"
              className={classNames(css.field, css.customField)}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentPortfolioForm.linkedinPlaceholder',
              })}
            />
            <FieldTextInput
              id={`${formId}.instagram`}
              name="instagram"
              className={classNames(css.field, css.customField)}
              type="text"
              placeholder={intl.formatMessage({
                id: 'CreateTalentPortfolioForm.instagramPlaceholder',
              })}
              validate={validators.validInstagramLink(
                intl.formatMessage({ id: 'CreateTalentPortfolioForm.instagramValid' })
              )}
            />
          </div>
        </div>

        <Field
          accept={ACCEPT_IMAGES}
          id={`${formId}.profileImage`}
          name="profileImage"
          label={chooseLogoLabel}
          type="file"
          form={null}
          validate={profileImageRequired}
        >
          {fieldProps => {
            const { accept, id, input, label, disabled } = fieldProps;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              if (file) {
                form.change(`profileImage`, file);
                form.blur(`profileImage`);
                setUploadedFile(file);
              }
            };

            let error = null;

            return (
              <div className={css.fieldContainer}>
                <span className={css.fieldLabel}>
                  <FormattedMessage id="CreateTalentPortfolioForm.profileLogoLabel" />
                </span>
                <p className={css.fieldText}>
                  <FormattedMessage id="CreateTalentPortfolioForm.profileLogoText" />
                </p>
                <label className={css.uploadLabel} htmlFor={id}>
                  {label}
                </label>
                <input
                  accept={accept}
                  id={id}
                  name={name}
                  className={css.uploadInput}
                  disabled={disabled}
                  onChange={onChange}
                  type={type}
                />
                {error}
              </div>
            );
          }}
        </Field>
        <div className={css.passwordContainer}>
          <span className={css.fieldLabel}>Password</span>

          <div className={css.fieldFlex}>
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="password"
              placeholder={intl.formatMessage({
                id: 'CreateTalentBasicsForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
              name="confirmPassword"
              autoComplete="password"
              placeholder={intl.formatMessage({
                id: 'CreateTalentBasicsForm.confirmPasswordPlaceholder',
              })}
              validate={confirmPasswordValidators}
            />
          </div>
        </div>

        <div className={css.fieldContainer}>
          <FieldCheckboxGroup
            name="newsletter"
            id={`${formId}.newsletter`}
            className={css.newsLetter}
            optionLabelClassName={css.newsLetterfieldText}
            checkboxClassName={css.customCheckbox}
            options={[
              {
                key: 'newsletter-checked',
                label: intl.formatMessage({
                  id: 'CreateTalentPortfolioForm.newsLetter',
                }),
              },
            ]}
          />
        </div>
      </div>
      <SecondaryButton
        className={css.submitButton}
        type="submit"
        inProgress={submitInProgress}
        disabled={submitDisabled}
        ready={submitReady}
      >
        {submitInProgress ? publishProfileMessage : saveActionMsg}
      </SecondaryButton>
    </TalentFormContent>);
};

export default TalentSignUpPortfolioForm;
