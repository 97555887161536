import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import CheckSVG from '../../assets/mdi_check.svg';
import css from './AboutPage.module.css';
import { Heading } from '../../components';
import { useAnimatedInView } from './AboutPage.shared';

export const SectionChallenges = props => {
  const { sectionId, isMobileLayout } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <div id={sectionId} className={css.SectionChallenges}>
      <div className={css.animatedContainer} ref={ref}>
        <AnimatedDiv>
          <Heading className={css.SectionCustomTitle} as="h2">
            <FormattedMessage id="AboutPage.SectionChallenges.title" />
          </Heading>
        </AnimatedDiv>
        <AnimatedDiv>
          <div className={css.challengesContent}>
            <div className={css.challengesText}>
              <div className={css.challengesTextTitle}>
                <FormattedMessage id="AboutPage.SectionProblem.textLine1" />
              </div>
              <FormattedMessage id="AboutPage.SectionChallenges.textDescriptionContent" />
            </div>
            <div className={css.vl}></div>
            <div className={css.challengesText}>
              <div className={css.challengesTextTitle}>
                <FormattedMessage id="AboutPage.SectionChallenges.textContent2" />
              </div>
              <FormattedMessage id="AboutPage.SectionChallenges.textDescriptionContent2" />
            </div>
            <div className={css.vl}></div>
            <div className={css.challengesText}>
              <div className={css.challengesTextTitle}>
                <FormattedMessage id="AboutPage.SectionChallenges.textContent3" />
              </div>
              <FormattedMessage id="AboutPage.SectionChallenges.textDescriptionContent3" />
            </div>
          </div>
        </AnimatedDiv>
        <AnimatedDiv delay={0.5}>
          <div className={css.videoContent}>
            <div className={css.videoTextContent}>
              <div className={css.videoTextTitle}>
                <FormattedMessage
                  id="LandingPage.SectionHome.videotextTitle"
                  values={{ boldText: <span style={{ fontWeight: 'bold' }}>Duke Talent.io</span> }}
                />
              </div>
              <div className={css.videoTextDescription}>
                Duke Talent.io redefines the job search process with a vetted and nimble online
                platform that matches leading designers and architects with a curated list of top
                studios.
              </div>
              <div>
                <div className={css.videoContentWithSVG}>
                  <img src={CheckSVG} />
                  <div>
                    <FormattedMessage
                      id="AboutPage.SectionSolution.textLine2"
                      values={{ boldText: <span style={{ fontWeight: 'bold' }}>transparent</span> }}
                    />
                  </div>
                </div>
                <div className={css.videoContentWithSVG}>
                  <img src={CheckSVG} />
                  <div>
                    <FormattedMessage
                      id="AboutPage.SectionSolution.textLine3"
                      values={{ boldText: <span style={{ fontWeight: 'bold' }}>curated</span> }}
                    />
                  </div>
                </div>
                <div className={css.videoContentWithSVG}>
                  <img src={CheckSVG} />
                  <div>
                    Designed to <strong>empower</strong> you, allowing you to take control over your
                    careers.
                  </div>
                </div>
              </div>
            </div>
            <div className={css.videoYoutubeContent}>
              <iframe
                style={{ width: '100%', height: '100%', border: 'none' }}
                src={`https://www.youtube.com/embed/492J8i7R0do?si=0QcE8E67o99g_hrO`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded YouTube"
              />
            </div>
          </div>
        </AnimatedDiv>
      </div>
    </div>
  );
};

export default SectionChallenges;
