//
// Configuration for the AddProjectForm component,
// specifically designed for internal use within the component.
//
// This configuration is not utilized outside of the AddProjectForm component,
// which is why it is defined in the AddProjectForm.shared.js file.
//
// By separating it into its own file, we maintain better organization
// and encapsulation of the component's internal logic and settings.
//
// Modifying or extending this configuration should only be done
// within the AddProjectForm component to ensure consistency
// and avoid unintended side effects in other parts of the application.
//
export const completedDateOptions = [
  { key: '2023', label: '2023' },
  { key: '2022', label: '2022' },
  { key: '2021', label: '2021' },
  { key: '2020', label: '2020' },
  { key: '2019', label: '2019' },
  { key: '2018', label: '2018' },
  { key: '2017', label: '2017' },
  { key: '2016', label: '2016' },
  { key: '2015', label: '2015' },
  { key: '2014', label: '2014' },
  { key: '2013', label: '2013' },
  { key: '2012', label: '2012' },
  { key: '2011', label: '2011' },
  { key: '2010', label: '2010' },
  { key: '2009', label: '2009' },
  { key: '2008', label: '2008' },
  { key: '2007', label: '2007' },
  { key: '2006', label: '2006' },
  { key: '2005', label: '2005' },
  { key: '2004', label: '2004' },
  { key: '2003', label: '2003' },
  { key: '2002', label: '2002' },
  { key: '2001', label: '2001' },
  { key: '2000', label: '2000' },
];
