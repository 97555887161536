import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCouch.module.css';

const IconCouch = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="16"
      fill="none"
      viewBox="0 0 23 16"
    >
      <path
        stroke="#B60101"
        strokeWidth="0.989"
        d="M21.824 12.156a1.486 1.486 0 01-1.04.453h-.767v2.224h-3.46V12.61H6.67v2.224h-3.46V12.61h-.768a1.486 1.486 0 01-1.039-.453l-.355.344.355-.344a1.485 1.485 0 01-.417-1.058h0v-5.21c.04-.66.58-1.177 1.24-1.188a1.24 1.24 0 011.108 1.183v4.378h16.558V5.882A1.23 1.23 0 0120.999 4.7c.66.01 1.202.528 1.242 1.188v5.202h0v.008c.006.394-.144.775-.417 1.058z"
      ></path>
      <path
        stroke="#B60101"
        strokeLinecap="round"
        strokeWidth="0.989"
        d="M20.51 4.453a3.336 3.336 0 00-3.336-3.336H6.054a3.336 3.336 0 00-3.337 3.336"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconCouch.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCouch.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCouch;
