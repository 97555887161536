import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

// Import util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { generateRandomUUID } from '../../../../util/uuid';
import { emailFormatValidWithoutForm } from '../../../../util/validators';

// Import shared components
import { Heading } from '../../../../components';
// Import modules from this directory
import { useDispatch } from 'react-redux';
import { updateorcreateMailChimpMember } from '../../../CreateTalentPage/CreateTalentPage.duck';
import PanelHeader from '../PanelHeader';
import CreateCompanyBasicsForm from './CreateCompanyBasicsForm';
import css from './CreateCompanyBasicsPanel.module.css';

// When user types, we wait for new keystrokes a while before calling fn
const DEBOUNCE_WAIT_TIME = 600;

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateCompanyBasicsPanel = props => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    onEmailTaken,
    isEmailTaken,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();

  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = values => {
    const { email } = values;

    if (emailFormatValidWithoutForm(email)) {
      onEmailTaken(values);
    }
  };
  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });
  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const { email } = currentProfile || {};
  useEffect(() => {
    if (email && email != undefined) {
      debouncedSubmit({
        email: email
      });
    }
  }, []);

  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateCompanyBasicsPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateCompanyBasicsForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={currentProfile}
        onSubmit={async values => {
          try {
            setSubmitInProgress(true);
            const {
              id,
              name,
              email,
              password,
              // address,
              // secondaryAddress,
              companySize,
              website,
              instagram,
              location,
              secondaryLocation,
            } = values;

            // const companyFullAddress = `${address.addressLine1} ${address.city}`;
            // const placeDetails = await getPlaceDetails(companyFullAddress);
            // const geolocation = placeDetails?.origin;
            // const googleMapsUrl = placeDetails?.url;

            const { selectedPlace } = location;
            const geolocation = selectedPlace?.origin;
            const googleMapsUrl = selectedPlace?.url;

            if (!geolocation || !googleMapsUrl) {
              setSubmitError(true);
              setSubmitInProgress(false);
              alert('Location not specific, please fill in again!')
            } else {
              const splitAddr1 = selectedPlace?.address.split(',');
              const address = splitAddr1
                ? {
                  addressLine1: splitAddr1[0],
                  city: splitAddr1[1],
                  zip: splitAddr1[2],
                }
                : {};

              const { selectedPlace: secondarySelectedPlace } = values?.secondaryLocation || {};
              const splitAddr2 = secondarySelectedPlace?.address.split(',');
              const secondaryAddress = splitAddr2
                ? {
                  addressLine1: splitAddr2[0],
                  city: splitAddr2[1],
                  zip: splitAddr2[2],
                }
                : {};

              const updateValues = {
                id: id ? id : generateRandomUUID(),
                name,
                email,
                // password,
                address,
                secondaryAddress,
                companySize,
                website,
                instagram,
                geolocation,
                googleMapsUrl,
                location,
                secondaryLocation,
              };

              const createMailChimpObject = {
                firstName: name,
                email: email,
                step: 1,
                registertype: 'Clients',
              };
              dispatch(updateorcreateMailChimpMember(createMailChimpObject));
              setSubmitInProgress(false);
              onSubmit(updateValues);
            }

          } catch (e) {
            console.log(e);
            setSubmitError(true);
            setSubmitInProgress(false);
          }

        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress || submitInProgress}
        fetchErrors={{ ...errors, submitFormError: submitError, }}
        onEmailChangeWithDebounce={handleChangeWithDebounce}
        isEmailTaken={isEmailTaken}
        autoFocus
      />
    </div>
  );
};

CreateCompanyBasicsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateCompanyBasicsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  onEmailTaken: func.isRequired,
  isEmailTaken: bool.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

export default CreateCompanyBasicsPanel;
