import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { clearUpdatedTab as clearCompanyUpdatedTab, loadProfileDataFromLocalStorage as loadCompanyProfileDataFromLocalStorage } from '../CreateCompanyPage/CreateCompanyPage.duck';
import { clearUpdatedTab as clearTalentUpdatedTab, loadProfileDataFromLocalStorage as loadTalentProfileDataFromLocalStorage} from '../CreateTalentPage/CreateTalentPage.duck';

const { UUID } = sdkTypes;


export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';


const initialState = {
    userId: null,
    userListingRefs: [],
    userShowError: null,
    queryListingsInProgress: false,
    queryListingsError: null,
  };
  
  export default function landingPageReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
      case SET_INITIAL_STATE:
        return { ...initialState };
        
      default:
        return state;
    }
  }
  

export const setInitialState = () => ({
    type: SET_INITIAL_STATE,
  });

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
    const userId = new UUID(params.id);
  
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());
  
    return Promise.all([
        dispatch(clearCompanyUpdatedTab()),
        dispatch(loadCompanyProfileDataFromLocalStorage()),
        dispatch(clearTalentUpdatedTab()),
        dispatch(loadTalentProfileDataFromLocalStorage()),
    ]);
  };