import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_NAME_REQUEST = 'app/NameChangePage/SAVE_NAME_REQUEST';
export const SAVE_NAME_SUCCESS = 'app/NameChangePage/SAVE_NAME_SUCCESS';
export const SAVE_NAME_ERROR = 'app/NameChangePage/SAVE_NAME_ERROR';

export const SAVE_NAME_CLEAR = 'app/NameChangePage/SAVE_NAME_CLEAR';

export const RESET_PASSWORD_REQUEST = 'app/NameChangePage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/NameChangePage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/NameChangePage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveNameInProgress: false,
  saveNameError: null,
  nameChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_NAME_REQUEST:
      return {
        ...state,
        saveNameInProgress: true,
        saveNameError: null,
        nameChanged: false,
      };
    case SAVE_NAME_SUCCESS:
      return { ...state, saveNameInProgress: false, saveNameError: null, nameChanged: true };
    case SAVE_NAME_ERROR:
      return { ...state, saveNameInProgress: false, saveNameError: payload, nameChanged: false };

    case SAVE_NAME_CLEAR:
      return {
        ...state,
        saveNameInProgress: false,
        saveNameError: null,
        nameChanged: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveNameRequest = () => ({ type: SAVE_NAME_REQUEST });
export const saveNameSuccess = () => ({ type: SAVE_NAME_SUCCESS });
export const saveNameError = error => ({
  type: SAVE_NAME_ERROR,
  payload: error,
  error: true,
});

export const saveNameClear = () => ({ type: SAVE_NAME_CLEAR });

// ================ Thunks ================ //

const requestSaveNameToProfile = params => (dispatch, getState, sdk) => {
  const { name } = params;
  const firstName = name.firstName || name;
  const lastName = name.lastName || name;

  return sdk.currentUser
    .updateProfile(
      { firstName, lastName },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveNameError(storableError(e)));
      // pass the same error so that the SAVE_NAME_SUCCESS
      // action will not be fired
      throw e;
    });
};

const requestSaveNameToListing = params => (dispatch, getState, sdk) => {
  const { name } = params;
  const { currentUserListing } = getState().user;

  const firstName = name.firstName;
  const lastName = name.lastName;

  const title = firstName && lastName ? `${firstName} ${lastName}` : name;

  return sdk.ownListings
    .update({
      id: currentUserListing.id,
      title,
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
      }

      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveNameError(storableError(e)));
      // pass the same error so that the SAVE_NAME_SUCCESS
      // action will not be fired
      throw e;
    });
};

const saveNameToProfile = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveNameToProfile(params))
      .then(response => {
        dispatch(saveNameSuccess());
        return response;
      })
      // error action dispatched in requestSaveEmail
      .catch(e => null)
  );
};

const saveNameToListing = params => (dispatch, getState, sdk) => {
  return (
    dispatch(requestSaveNameToListing(params))
      .then(response => {
        dispatch(saveNameSuccess());
        return response;
      })
      // error action dispatched in requestSavePhoneNumber
      .catch(e => null)
  );
};

export const saveName = params => (dispatch, getState, sdk) => {
  dispatch(saveNameRequest());

  // order of promises: 1. profile, 2. listing
  const promises = [dispatch(saveNameToProfile(params)), dispatch(saveNameToListing(params))];

  return Promise.all(promises)
    .then(values => {
      const currentUser = values[0];

      dispatch(currentUserShowSuccess(currentUser));
      dispatch(saveNameSuccess());
    })
    .catch(e => null);
};

export const loadData = () => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
