import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { FieldCheckboxGroup, FieldDropzone, FieldTextInput, SecondaryButton } from '../../components';
import { CompanyFormContent } from './CompanySignUpPage';
import css from './CompanySignUpPage.module.css';

const ACCEPT_IMAGES = 'image/gif, image/jpeg, image/png';

const CompanySignUpPortfolioForm = props => {
  const {
    sectionId,
    formId,
    intl,
    form,
    setUploadedFile,
    profileImageRequired,
    chooseLogoLabel,
    files,
    setFiles,
    passwordValidators,
    confirmPasswordValidators,
    submitInProgress,
    submitDisabled,
    submitReady,
    saveActionMsg,
    values,
  } = props;
  const publishProfileMessage = intl.formatMessage({
    id: 'CreateTalentPortfolioForm.publishProfile',
  });
  return (
    <CompanyFormContent
      id={sectionId}
      title={intl.formatMessage({
        id: 'TalentSignUpPage.TalentSignUpPortfolioForm.title',
      })}
    >
      <div className={css.fieldsWrapper}>
        <Field
          accept={ACCEPT_IMAGES}
          id={`${formId}.profileImage`}
          name="profileImage"
          label={chooseLogoLabel}
          type="file"
          form={null}
          validate={profileImageRequired}
        >
          {fieldProps => {
            const { accept, id, input, label, disabled } = fieldProps;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              if (file) {
                form.change(`profileImage`, file);
                form.blur(`profileImage`);
                setUploadedFile(file);
              }
            };

            let error = null;

            return (
              <div className={css.uploadWrapper}>
                <span className={css.fieldLabel}>
                  <FormattedMessage id="CreateCompanyPortfolioForm.companyLogoLabel" />
                </span>
                <p className={css.fieldText}>
                  <FormattedMessage id="CreateCompanyPortfolioForm.companyLogoText" />
                </p>
                <label className={css.uploadLabel} htmlFor={id}>
                  {label}
                </label>
                <input
                  accept={accept}
                  id={id}
                  name={name}
                  className={css.uploadInput}
                  disabled={disabled}
                  onChange={onChange}
                  type={type}
                />
                {error}
              </div>
            );
          }}
        </Field>

        <div className={css.fieldContainer}>
          <span className={css.fieldLabel}>
            <FormattedMessage id="CreateCompanyPortfolioForm.imagesLabel" />
          </span>
          <p className={css.fieldText}>
            <FormattedMessage id="CreateCompanyPortfolioForm.imagesText" values={{}} />
          </p>
          <FieldDropzone
            className={css.imagesField}
            id={`${formId}.images`}
            name="images"
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className={css.passwordContainer}>
          <span className={css.fieldLabel}>Password</span>
          <div className={css.fieldFlex}>
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              placeholder={intl.formatMessage({
                id: 'CreateCompanyBasicsForm.passwordPlaceholder',
              })}
              validate={passwordValidators}
            />
            <FieldTextInput
              className={css.field}
              type="password"
              id={formId ? `${formId}.confirm-password` : 'confirm-password'}
              name="confirm-password"
              autoComplete="new-password"
              placeholder={intl.formatMessage({
                id: 'CreateCompanyBasicsForm.confirmPasswordPlaceholder',
              })}
              validate={confirmPasswordValidators}
            />
          </div>
        </div>

        <div className={css.fieldContainer}>
          <FieldCheckboxGroup
            name="newsletter"
            id={`${formId}.newsletter`}
            className={css.newsLetter}
            optionLabelClassName={css.newsLetterfieldText}
            checkboxClassName={css.customCheckbox}
            options={[
              {
                key: 'newsletter-checked',
                label: intl.formatMessage({ id: 'CreateCompanyPortfolioForm.newsLetter' }),
              },
            ]}
          />
        </div>
      </div>
      <SecondaryButton
        className={css.submitButton}
        type="submit"
        inProgress={submitInProgress}
        disabled={submitDisabled}
        ready={submitReady}
      >
        {submitInProgress ? publishProfileMessage : saveActionMsg}
      </SecondaryButton>
    </CompanyFormContent>
  );
};

export default CompanySignUpPortfolioForm;
