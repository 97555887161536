import classNames from 'classnames';
import { array, bool, func, object, string } from 'prop-types';
import React from 'react';

// Import util modules
import { FormattedMessage } from '../../../../util/reactIntl';

// Import shared components
import { Heading } from '../../../../components';

// Import modules from this directory
import { useDispatch } from 'react-redux';
import PanelHeader from '../PanelHeader';
import CreateTalentPortfolioForm from './CreateTalentPortfolioForm';
import css from './CreateTalentPortfolioPanel.module.css';
import { updateorcreateMailChimpMember } from '../../CreateTalentPage.duck';

const findTabIndex = (tabs, tab) => tabs.findIndex(t => t === tab) + 1;

const CreateTalentPortfolioPanel = props => {
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    onRedirectToPreviousTab,
    onManageDisableScrolling,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    currentProfile,
    errors,
    config,
    marketplaceTabs,
    currentTab,
    tabLabel,
  } = props;
  const dispatch = useDispatch();
  const { email } = currentProfile || {};

  const classes = classNames(rootClassName || css.root, className);

  const availabilityInitialValue = currentProfile?.availability
    ? { availability: currentProfile.availability[0] }
    : {};
  const initialValues = currentProfile ? { ...currentProfile, ...availabilityInitialValue } : {};
  return (
    <div className={classes}>
      <PanelHeader
        tabLabel={tabLabel}
        order={findTabIndex(marketplaceTabs, currentTab)}
        length={marketplaceTabs.length}
      />
      <Heading className={css.title} as="h2">
        <FormattedMessage
          id="CreateTalentPortfolioPanel.createListingTitle"
          values={{ lineBreak: <br /> }}
        />
      </Heading>

      <CreateTalentPortfolioForm
        className={css.form}
        saveActionMsg={submitButtonText}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            availability,
            workplacePreference,
            openTo,
            website,
            linkedin,
            instagram,
            profileImage,
            images,
            newsletter,
            password,
          } = values;

          const updateValues = {
            availability: [availability],
            workplacePreference,
            openTo,
            website,
            linkedin,
            instagram,
            profileImage,
            images,
            password
          };

          if (newsletter && Array.isArray(newsletter) && newsletter.length > 0) {
            const mailchimpMember = {
              email,
              step: 5,
              registertype:'Candidates'
            };

            dispatch(updateorcreateMailChimpMember(mailchimpMember));
          }else{
            // step 6 is stand for unsubsribe
            const mailchimpMember = {
              email,
              step: 6,
              registertype:'Candidates'
            };

            dispatch(updateorcreateMailChimpMember(mailchimpMember));
          }

          onSubmit(updateValues);
        }}
        onRedirectToPreviousTab={onRedirectToPreviousTab}
        onManageDisableScrolling={onManageDisableScrolling}
        marketplaceCurrency={config.currency}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
      />
    </div>
  );
};

CreateTalentPortfolioPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  marketplaceTabs: null,
  currentTab: 'basics',
  tabLabel: null,
};

CreateTalentPortfolioPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onRedirectToPreviousTab: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  marketplaceTabs: array.isRequired,
  currentTab: string.isRequired,
  tabLabel: string.isRequired,
};

export default CreateTalentPortfolioPanel;
