import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import * as validators from '../../../../util/validators';
import { isSignupEmailTakenError } from '../../../../util/errors';

// Import shared components
import {
  Form,
  FieldDropzone,
  SecondaryButton,
  ChangeableImageFromFile,
  FieldCheckboxGroup,
  FieldTextInput,
} from '../../../../components';
import IconPlus from '../../../SearchPage/IconPlus/IconPlus';

// Import modules from this directory
import PanelFooter from '../PanelFooter';
import css from './CreateCompanyPortfolioForm.module.css';

const ACCEPT_IMAGES = 'image/gif, image/jpeg, image/png';
const MIN_IMAGES = 1;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { signupError, updateListingError } = fetchErrors || {};

  const signupErrorMessage = signupError ? (
    isSignupEmailTakenError(signupError) ? (
      <FormattedMessage id="CreateCompanyPortfolioForm.signupFailedEmailAlreadyTaken" />
    ) : (
      <FormattedMessage id="CreateCompanyPortfolioForm.signupFailed" />
    )
  ) : null;
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateCompanyPortfolioForm.updateFailed" />
  ) : null;

  if (signupErrorMessage) {
    return <p className={css.error}>{signupErrorMessage}</p>;
  } else if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }

  return null;
};

const CreateCompanyPortfolioFormComponent = props => {
  const uploadedFileFromInitialValues = props.initialValues.profileImage
    ? props.initialValues.profileImage
    : null;
  const uploadedFilesFromInitialValues = props.initialValues.images
    ? props.initialValues.images
    : [];

  const [uploadedFile, setUploadedFile] = useState(uploadedFileFromInitialValues);
  const [files, setFiles] = useState(uploadedFilesFromInitialValues);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          formId,
          form,
          values,
          intl,
          handleSubmit,
          onRedirectToPreviousTab,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const imageId = uploadedFile && String(uploadedFile.lastModified);
        const imageFromFile = uploadedFile ? (
          <ChangeableImageFromFile
            id={imageId}
            rootClassName={css.imageFromFile}
            aspectWidth={1}
            aspectHeight={1}
            file={uploadedFile}
          />
        ) : null;

        const chooseLogoLabel = imageFromFile ? (
          <div className={css.imageFromFileWrapper}>
            {imageFromFile}
            <div className={css.imageFromFileLabelWrapper}>
              <span className={css.imageFromFileLabel}>
                <FormattedMessage id="CreateCompanyPortfolioForm.changeLogoLabel" />
              </span>
            </div>
          </div>
        ) : (
          <div className={css.uploadZone}>
            <span className={css.uploadZoneLabel}>
              <FormattedMessage id="CreateCompanyPortfolioForm.chooseLogoLabel" />
            </span>
            <span className={css.uploadZoneIconWrapper}>
              <IconPlus className={css.uploadZoneIcon} isOpen={false} />
            </span>
          </div>
        );

        const profileImageRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyPortfolioForm.profileImageRequired',
        });
        const profileImageRequired = validators.required(profileImageRequiredMessage);

        const publishProfileMessage = intl.formatMessage({
          id: 'CreateCompanyPortfolioForm.publishProfile',
        });

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'CreateCompanyBasicsForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'CreateCompanyBasicsForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const confirmPasswordRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.confirmPasswordRequired',
        });
        const confirmPasswordInvalidMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.confirmPasswordInvalid',
        });
        const confirmPasswordRequired = validators.required(confirmPasswordRequiredMessage);
        const confirmPasswordInvalid = validators.passwordMatch(
          confirmPasswordInvalidMessage,
          values.password
        );
        const confirmPasswordValidators = validators.composeValidators(
          confirmPasswordRequired,
          confirmPasswordInvalid
        );

        const imagesFromValues = values.images;
        const hasEnoughImages = imagesFromValues?.length >= MIN_IMAGES;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !hasEnoughImages;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <ErrorMessage fetchErrors={fetchErrors} />

            <Field
              accept={ACCEPT_IMAGES}
              id={`${formId}.profileImage`}
              name="profileImage"
              label={chooseLogoLabel}
              type="file"
              form={null}
              validate={profileImageRequired}
            >
              {fieldProps => {
                const { accept, id, input, label, disabled } = fieldProps;
                const { name, type } = input;
                const onChange = e => {
                  const file = e.target.files[0];
                  if (file) {
                    form.change(`profileImage`, file);
                    form.blur(`profileImage`);
                    setUploadedFile(file);
                  }
                };

                let error = null;

                return (
                  <div className={css.uploadWrapper}>
                    <span className={css.fieldLabel}>
                      <FormattedMessage id="CreateCompanyPortfolioForm.companyLogoLabel" />
                    </span>
                    <p className={css.fieldText}>
                      <FormattedMessage id="CreateCompanyPortfolioForm.companyLogoText" />
                    </p>
                    <label className={css.uploadLabel} htmlFor={id}>
                      {label}
                    </label>
                    <input
                      accept={accept}
                      id={id}
                      name={name}
                      className={css.uploadInput}
                      disabled={disabled}
                      onChange={onChange}
                      type={type}
                    />
                    {error}
                  </div>
                );
              }}
            </Field>

            <div className={css.fieldContainer}>
              <span className={css.fieldLabel}>
                <FormattedMessage id="CreateCompanyPortfolioForm.imagesLabel" />
              </span>
              <p className={css.fieldText}>
                <FormattedMessage id="CreateCompanyPortfolioForm.imagesText" values={{}} />
              </p>
              <FieldDropzone
                className={css.imagesField}
                id={`${formId}.images`}
                name="images"
                files={files}
                setFiles={setFiles}
              />
            </div>

            <div className={css.passwordContainer}>
            <span className={css.fieldLabel}>Password</span>
              <div className={css.fieldFlex}>
                <FieldTextInput
                  className={css.field}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <FieldTextInput
                  className={css.field}
                  type="password"
                  id={formId ? `${formId}.confirm-password` : 'confirm-password'}
                  name="confirm-password"
                  autoComplete="new-password"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.confirmPasswordPlaceholder',
                  })}
                  validate={confirmPasswordValidators}
                />
              </div>
            </div>

            <div className={css.fieldContainer}>
              <FieldCheckboxGroup
                name="newsletter"
                id={`${formId}.newsletter`}
                className={css.newsLetter}
                optionLabelClassName={css.newsLetterfieldText}
                checkboxClassName={css.customCheckbox}
                options={[
                  {
                    key: 'newsletter-checked',
                    label: intl.formatMessage({ id: 'CreateCompanyPortfolioForm.newsLetter' }),
                  },
                ]}
              />
            </div>

            <PanelFooter className={css.footer} onRedirectToPreviousTab={onRedirectToPreviousTab}>
              <SecondaryButton
                className={css.submitButton}
                type="submit"
                // inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {submitInProgress ? publishProfileMessage : saveActionMsg}
              </SecondaryButton>
            </PanelFooter>
          </Form>
        );
      }}
    />
  );
};

CreateCompanyPortfolioFormComponent.defaultProps = {
  className: null,
  formId: 'CreateCompanyPortfolioForm',
  fetchErrors: null,
};

CreateCompanyPortfolioFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(CreateCompanyPortfolioFormComponent);
