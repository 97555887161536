import classNames from 'classnames';
import React from 'react';

import css from './AboutPage.module.css';
import {
  OFFSET_DESKTOP,
  OFFSET_MOBILE,
  scrollToSection,
  useActiveNavItem,
} from './AboutPage.shared';

export const HOME = 'home';
export const INTRODUCTION = 'introduction';
export const ABOUT = 'about';
export const PROBLEM = 'problem';
export const SOLUTION = 'solution';
export const HOW_IT_WORKS = 'how-it-works';
export const REVIEWS = 'reviews';
export const FAQS = 'faqs';
export const CHALLENGES = 'challenges';

export const BASICS = 'basic';
export const WORKS = 'work';
export const SPECIFICS = 'specific';
export const STYLES = 'style';
export const PORTFOLIO = 'portfolio';
export const INDUSTRY = 'industry';

// export const NAV = [ABOUT, PROBLEM, SOLUTION, HOW_IT_WORKS, REVIEWS, FAQS];

/**
 * Returns the localized label for the given item.
 * @param {Object} intl - The intl object used for localization.
 * @param {string} item - The item key for which to get the label.
 * @returns {string | null} The localized label for the item, or null if the item is not recognized.
 */
const itemLabel = (intl, item) => {
  let key = null;

  if (item === HOME) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemHome' });
  }
  if (item === CHALLENGES) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemChallenges' });
  } else if (item === INTRODUCTION) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemIntro' });
  } else if (item === ABOUT) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemAbout' });
  } else if (item === PROBLEM) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemProblem' });
  } else if (item === SOLUTION) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemSolution' });
  } else if (item === HOW_IT_WORKS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemHowItWorks' });
  } else if (item === REVIEWS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemReviews' });
  } else if (item === FAQS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemFaqs' });
  } else if (item === BASICS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemBasics' });
  } else if (item === WORKS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemWorks' });
  } else if (item === SPECIFICS) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemSpecifics' });
  } else if (item === STYLES) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemStyles' });
  } else if (item === PORTFOLIO) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemPortfolios' });
  }else if (item === INDUSTRY) {
    key = intl.formatMessage({ id: 'AboutPage.AsideNav.itemIndustry' });
  }

  return key;
};

/**
 * Determines the scroll offset based on the layout mode.
 * @param {boolean} isMobileLayout - Indicates whether the layout is mobile or desktop.
 * @returns {number} The scroll offset based on the layout mode.
 */
const scrollOffset = isMobileLayout => (isMobileLayout ? OFFSET_MOBILE : OFFSET_DESKTOP);

export const AsideNav = props => {
  const { intl, isMobileLayout, NAV } = props;

  const sectionIds = NAV;
  const activeNavItem = useActiveNavItem(sectionIds);
  return (
    // <nav className={css.asideNav}>
    <nav
      className={classNames(css.asideNav, {
        [css.hideNav]: activeNavItem === HOME,
      })}
    >
      <ul className={css.asideNavList}>
        {NAV.map(item => {
          const offset = scrollOffset(isMobileLayout);
          return (
            <li
              key={item}
              className={classNames(css.asideNavListItem, {
                [css.asideNavListActiveItem]: activeNavItem === item,
              })}
              onClick={() => scrollToSection(item, offset, isMobileLayout)}
            >
              {itemLabel(intl, item)}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default AsideNav;
