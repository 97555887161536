/**
 * Slice and format number in US format
 * i.e 123 456 7890
 */
const sliceInternational = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 6) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 6)} ${numbers.slice(6, 10)}`;
};

export const format = value => {
  if (!value) {
    return '';
  }

  const numbers = value.replace(/[^\d]/g, '');
  const sliced = sliceInternational(numbers);

  return sliced;
};

/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => (value ? value.replace(/\s/g, '') : '');
