import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEdit.module.css';

const IconEdit = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#B60101"
        d="M13.51 1.798a2.825 2.825 0 013.078 4.607l-.41.41-3.994-3.994.41-.41c.262-.263.573-.47.916-.613zM11.064 3.94l-8.172 8.173a.792.792 0 00-.204.351L1.61 16.417a.792.792 0 00.972.972l3.953-1.078a.79.79 0 00.351-.204l8.172-8.172-3.994-3.995z"
      ></path>
    </svg>
  );
};

IconEdit.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEdit.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEdit;
